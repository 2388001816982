import Vue from 'vue';
Vue.filter('wCount', (item, measureUnit) => {
    if (!item) {
        return '0';
    }
    if (!measureUnit) {
        measureUnit = '次';
    }
    if (item >= 99500) {
        return (item / 10000).toFixed(0) + '万';
    }
    if (item >= 10000) {
        return (item / 10000).toFixed(1) + '万' + measureUnit;
    }
    else if (item >= 1000) {
        return Math.floor(item / 1000).toFixed(0) + '千' + measureUnit;
    }
    else {
        return item.toString() + measureUnit;
    }
});
