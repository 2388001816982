import { render, staticRenderFns } from "./AboutPage.vue?vue&type=template&id=44ab1ab1"
import script from "./AboutPage.vue?vue&type=script&lang=js"
export * from "./AboutPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  "eb8a63f0"
  
)

export default component.exports