<template>
    <div class="w-card w-cat-page-filter">
        <h2 class="w-cat-page-filter-name">{{(cat.name||'热门城市')+'相关分站'}}</h2>
        <ul class="w-cat-page-filter-content">
            <li v-for="c in settings.hotCities" :key="c.id">
                <router-link :to="makeUrl(c)">{{c.name+(cat.name ||s.PROD_NAME)}}</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import { removeParam, makeCityUrl, makeCityIndexUrl } from '@/utils/link'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      settings: 'main/search/settings',
      cat: 'main/search/cat' })
  },
  methods: {
    makeUrl (city) {
      const path = this.$route.path
      let url = removeParam(path, 'page,keywords,cityid,areaid,streetid')
      if (path.indexOf('search') && !this.cat.name) {
        return makeCityIndexUrl(city)
      } else {
        return makeCityUrl(url, city.dir)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.w-cat-page-filter-name {
    flex: 0 0 120px;
}
.w-cat-page-filter {
    padding: 15px;
}

</style>
