import { render, staticRenderFns } from "./IndexPage.vue?vue&type=template&id=5e43a4bc&scoped=true"
import script from "./IndexPage.vue?vue&type=script&lang=js"
export * from "./IndexPage.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./IndexPage.vue?vue&type=style&index=0&id=5e43a4bc&prod&lang=less&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5e43a4bc",
  "381b29cf"
  
)

export default component.exports