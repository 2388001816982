import { isServer } from '@/utils/clientEnv';
const settings = {
    // 积分与余额兑换比例
    CFG_SCORE_FEE: 100,
    // 是否开启统计，极端性能情况下，可考虑关闭。
    STATS_ENABLED: true,
    USERAGREEMENT_VERSION: 20190801,
    PROD_NAME: '服装八场',
    SLOGAN: '服装八场，诚信赢天下',
    wxappid: '',
    WX_KF_ID: 'fzwjg009',
    WX_KF_NAME: '小九',
    KF_TEL: 'tel:4009998916',
    KF_TEL_FORMATED: '400-999-8916',
    ATTACHMENT_URL: 'https://attachment.fzwjg.com', // 图片都在这里，本地不存储图片
    isDev: () => {
        return process.env.NODE_ENV === 'development';
    },
    apiBaseUrl: () => {
        return isServer ? process.env.VUE_APP_SSR_API : process.env.VUE_APP_CLIENT_API;
    },
    mUrl: () => {
        return isServer ? process.env.VUE_APP_M_URL : process.env.VUE_APP_M_URL;
    },
    wUrl: () => {
        return isServer ? process.env.VUE_APP_W_URL : process.env.VUE_APP_W_URL;
    },
    requestTimeout: () => {
        return parseInt(process.env.VUE_APP_REQUEST_TIMEOUT);
    }
};
export default settings;
