import '../assets/libs/iconfont/iconfont.css';
import settings from '@/core/settings';
import { isServer } from '@/utils/clientEnv';
const trackBaidu = () => {
    const s = document.createElement('script');
    s.text = `var _hmt = _hmt || [];
  (function() {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?4ecb05ac2fa5650a24c05e3cde3d9dfe";
    var s = document.getElementsByTagName("script")[0]; 
    s.parentNode.insertBefore(hm, s);
  })();`;
    document.head.appendChild(s);
};
// This is your plugin object. It can be exported to be used anywhere.
const plugin = {
    // The install method is all that needs to exist on the plugin object.
    // It takes the global Vue object as well as user-defined options.
    install(Vue, options) {
        if (!isServer && !settings.isDev()) {
            trackBaidu();
        }
    }
};
export default plugin;
