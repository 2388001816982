<template>
  <div class="w-app">
    <w-header class="w-header"/>
    <main class="w-body">
      <router-view class="w-main"/>
      <route-details v-if="false"></route-details>
      <aside class="w-left"/>
      <aside class="w-right"/>
    </main>
    <w-footer class="w-footer"/>
  </div>
</template>

<script>
import WHeader from '@/views/layout/components/WHeader.vue'
import WFooter from '@/views/layout/components/WFooter.vue'
import RouteDetails from '@/views/layout/components/RouteDetails.vue'

export default {
  name: 'mainLayout',
  components: {
    WHeader,
    WFooter,
    RouteDetails
  },
  metaInfo: {} // 必须保留，否则没有设置metaInfo的页面会报错
}
</script>

<style lang="less" scoped>
</style>
