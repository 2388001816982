<template>
  <div class="breadcrumb">
    <span v-for="(item,i) in Indexitem.concat(items)" :key="i">
      <i class="iconfont icon-shouye" v-if="i === 0"></i>
      <router-link  :to="item.url">{{item.name}}</router-link>
      <i class="iconfont icon-arrow-right" v-if="i < items.length"></i>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    items: { type: Array, required: true },
    city: { type: Object, required: false, default: () => { return { id: 0, name: '全国' } } }
  },
  computed: {
    cityName () {
      return this.city.id === 0 ? '' : this.city.name
    },
    Indexitem () {
      return [{ name: this.cityName + this.s.PROD_NAME, url: 'index.html' }]
    }
  }
}
</script>

<style lang="less" scoped>
.breadcrumb {
    padding-top: 5px;
    span {
        font-size: 12px;
        color: #888;
    }
    .iconfont {
        padding: 0px 3px;
        font-size: 12px;
    }
}
</style>
