import { render, staticRenderFns } from "./ArticleIndexHotInfo.vue?vue&type=template&id=63e1c017&scoped=true"
import script from "./ArticleIndexHotInfo.vue?vue&type=script&lang=js"
export * from "./ArticleIndexHotInfo.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "63e1c017",
  "414b42be"
  
)

export default component.exports