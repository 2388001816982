import { render, staticRenderFns } from "./ShopInfoPage.vue?vue&type=template&id=69ef6890&scoped=true"
import script from "./ShopInfoPage.vue?vue&type=script&lang=js"
export * from "./ShopInfoPage.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "69ef6890",
  "182bf00c"
  
)

export default component.exports