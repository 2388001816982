<template>
  <div class='w-page flink'>
    <about-navigation :path="$route.path"/>
    <div class="w-title">
      <h1>友情链接</h1>
    </div>
    <el-row class="w-content">
      <el-col v-for="(link, i) in links" :key="i" :xs="8" :sm="4" :md="3">
        <a :href="link.url" :underline="false" target="_blank">{{link.name}}</a>
      </el-col>
    </el-row>
    <div class="w-title">
       <h1>申请友链</h1>
    </div>
    <div class="w-content">
      <h2>申请步骤</h2>
    <!-- TODO: 地址需采用公共的常量 -->
      <div>
        1，请先在贵网站做好{{s.PROD_NAME}}的友情链接：<br/>
        <b>链接文字：{{s.PROD_NAME}}</b> <br/>
        <b>链接地址：{{s.wUrl()}}</b><br/>
        2，做好链接后，请在下方填写申请信息。 <br/>
        3，已经开通我站友情链接且内容健康，符合本站友情链接要求的网站，经管理员审核后，可以显示在此友情链接页面
      </div>
      <h2>提交申请</h2>
      <el-form
      label-position="left"
      inline
      label-width="85px"
      :model="postForm"
      :rules="rules"
      ref="postForm"
      >
      <el-row>
         <el-col>
           <el-form-item
             label="网站名称"
             prop="name"
           >
             <el-input
               v-model="postForm.name"
               size="mini"
               class="flink-input"
             />
           </el-form-item>
         </el-col>
       </el-row>

       <el-row>
         <el-col>
           <el-form-item
             label="网站地址"
             prop="url"
           >
             <el-input
               v-model="postForm.url"
               size="mini"
               class="flink-input"
             />
           </el-form-item>
         </el-col>
       </el-row>

       <el-row>
         <el-col>
           <el-form-item label="图片地址">
             <el-input
               v-model="postForm.log"
               size="mini"
               class="flink-input"
             />
           </el-form-item>
         </el-col>
       </el-row>

       <el-row>
         <el-col>
           <el-form-item label="电子邮箱">
             <el-input
               v-model="postForm.email"
               size="mini"
               class="flink-input"
             />
           </el-form-item>
         </el-col>
       </el-row>

       <el-row>
         <el-col>
           <el-form-item
             label="QQ号"
             prop="qq"
           >
             <el-input
               v-model="postForm.qq"
               size="mini"
               type="number"
               class="flink-input"
             />
           </el-form-item>
         </el-col>
       </el-row>

       <el-row>
         <el-col>
           <el-form-item
             label="联系电话"
             prop="tel"
           >
             <el-input
               v-model="postForm.tel"
               size="mini"
               type="number"
               class="flink-input"
             />
           </el-form-item>
         </el-col>
       </el-row>

       <el-row>
         <el-col>
           <el-form-item label="网站介绍">
             <el-input
               v-model="postForm.msg"
               type="textarea"
               :autosize="{minRows: 2, maxRows: 4}"
               placeholder="请输入内容"
               maxlength="200"
               show-word-limit
               size="mini"
               class="flink-input"
             />
           </el-form-item>
         </el-col>
       </el-row>
       <!-- TODO: 此处原网站有验证码 -->
       <div style="text-align: center;">
         <el-button
           type="primary"
           :disabled="btnDisabled"
           size="small"
           @click="submitForm('postForm')"
         >
           确认提交
         </el-button>
         <el-button
           @click="resetForm('postForm')"
           size="small"
         >重置</el-button>
       </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import AboutNavigation from '@/views/main/about/components/AboutNavigation.vue'
import * as api from '@/api/about'
import { mapGetters } from 'vuex'

export default {
  name: 'friendLink',
  components: { AboutNavigation },
  data () {
    return {
      postForm: {},
      // TODO: 可以做更精确的校验
      rules: {
        url: [{ required: true, message: '请输入url地址', trigger: 'blur' }],
        name: [{ required: true, message: '请输入地址名称', trigger: 'blur' }],
        qq: [{ required: true, message: '请输入QQ号', trigger: 'blur' }],
        tel: [{ required: true, message: '请输入联系方式', trigger: 'blur' }]
      },
      btnDisabled: false
    }
  },
  computed: {
    ...mapGetters({
      links: 'about/flink/links',
      city: 'global/city'
    })
  },
  serverPrefetch () {
    return this.fetchData()
  },
  mounted: function () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      const cityid = this.city.id
      return this.$store.dispatch('about/flink/getLinks', { cityid, catid: 0 })
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.postFLink()
        } else {
          this.$notify({
            title: '警告',
            message: '请输入必填项',
            type: 'warning',
            duration: 3500
          })
          return false
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },

    async postFLink () {
      this.btnDisabled = true
      api.createFLink(this.postForm).then((resp) => {
        this.$notify({
          title: '成功',
          message: '提交成功! 请等待审核',
          type: 'success',
          duration: 3500
        })
      }).catch((err) => {
        this.$notify({
          title: '错误',
          message: err,
          type: 'error',
          duration: 6000
        })
      })
    }
  },
  metaInfo () {
    return {
      title: '友情链接-' + this.s.PROD_NAME,
      meta: [
        { name: 'keywords', content: '友情链接，' + this.s.PROD_NAME },
        { name: 'description', content: this.s.PROD_NAME + '友情链接频道' }
      ]
    }
  }
}
</script>

<style lang="less" scoped>

.flink h2{
  margin: 20px 0
}

.w-content b {
  padding-left: 22px
}

.flink-input {
  width: 260px;
}

</style>
