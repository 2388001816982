<template>
  <div>
    <router-link v-if="info.main_img" :to="makeInfoUrl(info)" class="s-content-img">
      <w-img
        :opath="info.main_img"
        ostyle="s89"
        :alt="info.region_name+' - '+info.title"
      />
    </router-link>
    <div class="s-content">
      <div class="s-header">
        <div class="s-header-title">
          <router-link :to="makeInfoUrl(info)">
            <h2 v-html="info.title" class="info-title" />
          </router-link>
          <span v-if="info.is_top">
            <i class="iconfont icon-ding" />
          </span>
          <span v-if="info.certified>0">
            <i class="iconfont icon-shi" />
          </span>
          <span v-if="info.certified<=0 || !info.is_top">&nbsp;</span>
        </div>
        <div class="clearfix" />
      </div>
      <div class="s-body">
        <div
          :class="info.main_img? 's-text-multi':'s-text-single'"
          v-html="info.content"
        />
        <div class="clearfix" />
      </div>
      <div class="s-footer">
        <span
          v-if="info.is_top"
          class="xx6"
        >广告</span>
        <span
          v-if="time > info.active_end_time"
          class="xx6"
        >已过期</span>
        <span
          v-if="!info.is_top && time <= info.active_end_time"
          class="xx7"
        >{{ info.refresh_time | wTime }}</span>
        <span class="pull-right xx7">
          <i class="iconfont icon-wjg-dt-search" />
          {{ info.region_name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { makeInfoUrl } from '@/utils/link'

export default {
  props: ['info'],
  computed: {
    time () {
      return (new Date()).getTime()
    }
  },
  methods: {
    makeInfoUrl
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.s-content {
   padding: 0.6vh;
}

.s-header {
  margin-bottom: 10px;
}

.s-body {
  margin-bottom: 10px;
}

.s-footer {
   color: #9e9e9e;
   font-size: 12px;
   margin-top: 4px;
   span {
     margin-right: 5px;
   }
}

.s-spin {
   text-align: center;
   width: 100%;
}

.s-header-icons > span {
   margin-left: 2px;
}

.s-header-title {
   text-align: left;
   margin-right: 12vw;
   font-size: 16px;
   color: #303030;
   overflow: hidden;
   white-space: nowrap;
      span {
        margin: 0 2px;
      }
}

.info-title {
  padding-right: 15px;
  display: inline;
}

.s-content-img {
   float: left;
   margin-right: 15px;
   margin-top: 5px;
}

.s-content-img > img {
   width: 89px;
   height: 89px;
}

.s-text-single {
   color: #727272;
   font-size: 14px;
   margin-top: 4px;
   width: 100%;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}

.s-text-multi {
   color: #727272;
   font-size: 14px;
   display: block;
   display: -webkit-box;
   max-width: 100%;
   max-height: 3em;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
}
</style>
