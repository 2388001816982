<template>
    <div>
      <div class="w-cat-page-filter">
          <h2 class="w-cat-page-filter-name">地点：</h2>
          <ul class="w-cat-page-filter-content" v-if="!settings.areas">
              <li v-for="city in settings.hotCities" :key="city.id">
                  <router-link
                    :to="makeCityUrl(url, city.dir)"
                    :title="city.name+(cat.id?cat.name:s.PROD_NAME)"
                  >{{city.name}}</router-link>
              </li>
          </ul>
          <ul class="w-cat-page-filter-content" v-if="settings.areas">
              <li>
                <router-link :to="makeAreaUrl()" :class="sel.areaid ? '' : 'active'">不限</router-link>
              </li>
              <li v-for="area in settings.areas" :key="area.id">
                  <router-link
                    :to="makeAreaUrl(area.id)" :class="area.id===sel.areaid ? 'active' : ''"
                    :title="sel.cityname+area.name+(cat.id?cat.name:s.PROD_NAME)"
                  >
                      {{area.name}}
                  </router-link>
              </li>
          </ul>
      </div>
      <div class="w-cat-page-filter">
          <h2 class="w-cat-page-filter-name" />
          <ul class="w-cat-page-filter-content">
              <li v-for="street in settings.streets" :key="street.id">
                  <router-link
                    :to="appendParam(url, 'streetid', street.id)" :class="street.id===sel.streetid ? 'active' : ''"
                    :title="sel.cityname+sel.areaname+street.name+(cat.id?cat.name:s.PROD_NAME)"
                  >
                    {{street.name}}</router-link>
              </li>
          </ul>
      </div>
    </div>
</template>

<script>
import { isPositiveInteger } from '@/utils/validate'
import { removeParam, appendParam, makeCityUrl, buildUrl, parsePrefix, parseParamsFromUrl } from '@/utils/link'
import { isEmpty } from '@/utils/object'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      settings: 'main/search/settings', // 所有可选值,
      sel: 'main/search/selection',
      cat: 'main/search/cat'
    }),
    url () {
      return removeParam(this.$route.path, 'page,keywords')
    }
  },
  methods: {
    makeCityUrl,
    appendParam,
    makeAreaUrl (areaid) {
      const perfix = parsePrefix(this.url)
      const params = parseParamsFromUrl(this.url)
      let urlParams = {}
      if (!isEmpty(params.catid)) {
        urlParams = { catid: params.catid }
      }
      Object.assign(urlParams, { cityid: this.cityid })
      if (!isEmpty(areaid)) {
        Object.assign(urlParams, { areaid })
      }
      return buildUrl(perfix, urlParams)
    }
  }
}
</script>

<style lang="less" scoped>
.w-cat-page-filter {
    margin-bottom: 10px
}

.active {
    color: #57a2f9;
}
</style>
