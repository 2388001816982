<template>
  <div class="w-card interest-info">
    <h2>感兴趣的信息</h2>
    <ul class="w-list-v">
      <li v-for="info in list" :key="info.id">
        <simple-info-row :info="info" :displayTime="false" />
      </li>
    </ul>
    <p>
      <router-link :to="buildUrl('category',{catid:info.sub_catid})">
      查看更多{{info.city_name}}{{info.cat_name}}的信息 >>
      </router-link>
    </p>
  </div>
</template>

<script>
import simpleInfoRow from '@/components/SimpleInfoRow'
import { buildUrl } from '@/utils/link'

export default {
  props: ['list', 'info'],
  components: {
    simpleInfoRow
  },
  methods: {
    buildUrl
  }
}
</script>

<style lang="less" scoped>
.interest-info {
  h2 {
    font-size: 14px;
    font-weight: 700;
    padding: 15px 0 0 15px;
    color: #333;
  }
  p {
    padding-left: 15px;
    a {
      color: #00a0e6;
    }
  }
}
</style>
