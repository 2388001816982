<template>
    <div class="w-card">
      <div class="w-list-title">
        <h2>精彩图文</h2>
      </div>
      <div class="img-text-content">
        <div class="img-text-item w-card" v-for="a in (latestArticles || []).slice(0,6)" :key="a.id">
          <router-link :to="makeArticleUrl(a)">
          <w-img :opath="a.img_path" ostyle="news141x108" :alt="a.title"/>
          <span>{{a.title}}</span>
          </router-link>
        </div>
      </div>
    </div>
</template>

<script>
import WImg from '@/components/WImg.vue'
import { makeArticleUrl } from '@/utils/link'

export default {
  props: ['latestArticles'],
  components: {
    WImg
  },
  methods: {
    makeArticleUrl
  }

}
</script>

<style lang="less" scoped>
.img-text-content {
  background-color: #f6f6f6;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  .img-text-item {
    margin: 17px 0px 7px 0px;
    width: 141px;

    img {
      width: 141px;
      height: 108px;
    }
  }

  span {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 12px;
      padding: 0 2px 0px 5px;
  }
}
</style>
