<template>
  <div class="aa-body">
    <el-tabs v-model="active" type="card">
      <el-tab-pane label="公告" name="announce">
        <ul class="w-list-v">
          <li v-for="(announce, i) in announces" :key="i">
            <simple-announce-row :announce="announce" :displayTime="false" />
          </li>
        </ul>
      </el-tab-pane>
      <el-tab-pane label="新闻" name="article">
        <ul class="w-list-v">
          <li v-for="(article, i) in articles" :key="i">
            <simple-article-row :article="article" :displayTime="false" />
          </li>
        </ul>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import SimpleArticleRow from '@/components/SimpleArticleRow.vue'
import SimpleAnnounceRow from '@/components/SimpleAnnounceRow.vue'

export default {
  components: { SimpleArticleRow, SimpleAnnounceRow },
  props: ['articles', 'announces'],
  data () {
    return { active: 'announce' }
  }
}
</script>

<style lang="less" scoped>
.aa-body {
  background-color: #fff;
  border: #ddd 1px solid;
  border-radius: 4px;
}
</style>
