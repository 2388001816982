<template>
  <div class="w-page about">
     <about-navigation :path="$route.path"/>
     <div>
      <h1 class='w-title'>{{data.title}}</h1>
      <div  class="w-content" v-html="data.content"/>
     </div>
  </div>
</template>

<script>
import AboutNavigation from '@/views/main/about/components/AboutNavigation.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'about',
  components: { AboutNavigation },
  computed: {
    ...mapGetters({
      data: 'about/about/data'
    })
  },
  watch: {
    '$route' (to, from) {
      this.fetchData()
    }
  },
  serverPrefetch () {
    return this.fetchData()
  },
  mounted: function () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      return this.$store.dispatch('about/about/getData', this.$route.params.id)
    }
  },
  metaInfo () {
    return {
      title: this.data.title + '-' + this.s.PROD_NAME,
      meta: [
        { name: 'keywords', content: this.data.title + '，' + this.s.PROD_NAME },
        { name: 'description', content: this.s.PROD_NAME + this.data.title + '频道' }
      ]
    }
  }
}
</script>
