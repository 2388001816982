<template>
  <div class="w-card info">
    <div class="info-title item">
      <h1>{{info.title}}</h1>
      <span>{{ info.refresh_time | wTime }}</span>
      <span v-if="info.display>0">展示 {{info.display | wCount }}</span>
      <span v-if="info.hit>0">浏览 {{info.hit | wCount}}</span>
    </div>
    <div class="Basic-info item">
      <h2>基本信息</h2>
      <div class="horizontal"></div>
      <div class="Basic-info-content">
        <!-- TODO:置顶背景图未做 -->
        <div :class="'Basic-info-left status-'+info.status">
          <ul>
            <li>
              <label>区域：</label>
              <router-link :to="buildUrl('category',{catid: info.parent_catid})">{{info.city_name}}</router-link>
              <router-link
               :to="buildUrl('category',{catid: info.parent_catid, cityid: info.cityid, areaid: info.areaid})"
               v-if="info.area_name !== ''">{{info.area_name}}</router-link>
              <router-link
               :to="buildUrl('category',{catid: info.parent_catid, cityid: info.cityid, areaid: info.areaid, streetid: info.streetid})"
               v-if="info.area_name !== ''">{{info.street_name}}</router-link>
              <router-link
               :to="buildUrl('category',{catid: info.parent_catid, cityid: info.cityid, areaid: info.areaid, streetid: info.streetid})"
               v-if="info.area_name !== ''">{{info.village_name}}</router-link>
            </li>
            <li>
              <label>大类：</label>
              <router-link :to="buildUrl('category',{catid: info.parent_catid})">{{info.parent_name}}</router-link>
            </li>
            <li>
              <label>小类：</label>
              <router-link :to="buildUrl('category',{catid: info.sub_catid})">{{info.cat_name}}</router-link>
            </li>
          </ul>
        </div>
        <div class="Basic-info-right">
          <ul>
            <li>
            </li>
            <li>
              <label>联系人：</label>
              <span>{{info.contact_who}}</span>
            </li>
            <li>
              <label>联系电话：</label>
              <span class="tel">{{info.contact_tel}}</span>
            </li>
          </ul>
          <div v-if="info.id" class="qr-code">
            <div><span class="qr-text">微信扫码，马上联系！</span></div>
            <i v-if="!qrCodeUrl" class="el-icon-loading"></i>
            <img
              v-else
              :src="qrCodeUrl"
              :alt="info.title+'的联系方式'"/>
            <div><span class="qr-text">微信扫码，马上联系！</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="info-content item">
      <h2>信息详情</h2>
      <div class="horizontal"></div>
      <div class="content">
        <div>{{info.title}}</div>
        <div v-html="info.content" />
        <div class="note">联系我时务必说明是在 <b>{{ s.PROD_NAME }}</b> 看到的</div>
      </div>
    </div>
    <div class="info-imgs item" v-if="!isEmpty(info.imgs)">
      <h2>相关图片</h2>
      <div class="horizontal"></div>
      <ul class="w-list-h">
        <li v-for="(img, i) in info.imgs" :key="i">
          <w-img :opath="img" ostyle="s450x600" :alt="info.title+'-第'+(i+1)+'张图片'"/>
        </li>
      </ul>
    </div>
    <!-- <div class="info-map item">
      TODO : 地理位置
    </div> -->
    <div class="info-comment item">
      <h2>信息评论</h2>
      <div class="horizontal"></div>
      <span>以上是{{info.title}}的内容，如果您需要了解更多{{info.region_name}}附近与{{info.title}}类似的内容，请查看推荐信息和右边的分类信息。</span>
    </div>
  </div>
</template>

<script>
import { buildUrl } from '@/utils/link'
import WImg from '@/components/WImg'
import { isEmpty } from '@/utils/object'
import * as api from '@/api/info'

export default {
  props: ['info'],
  data () {
    return {
      qrCodeUrl: ''
    }
  },
  watch: {
    info: {
      handler (info) {
        if (info.id) this.setInfoQRCode(this.info.id)
      }
    }
  },
  components: {
    WImg
  },
  methods: {
    buildUrl,
    isEmpty,
    async setInfoQRCode (id) {
      try {
        const { data } = await api.qrCode(id)
        this.qrCodeUrl = data
      } catch (err) {
        this.qrCodeUrl = ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.info {
  padding: 20px;
}

.info-title {
  h1 {
    margin-bottom: 10px;
    font-weight: 500;
    color: #333;
  }
  span {
    color: #999;
    font-size: 14px;
    padding-right: 15px;
  }
}

.Basic-info-content {
  display: flex;
  ul {
    list-style: none;
    padding: 0px;
  }
  label {
    display: inline-block;
    width: 80px;
    height: 30px;
    text-align: right;
    white-space: nowrap;
  }
}

.Basic-info-left {
  flex: 0 0 49%;
  background-position: 40% 70%;
  background-repeat: no-repeat;
  a {
    margin-right: 5px;
  }
}

.Basic-info-right {
  flex: 0 0 49%;
  .tel {
    color: #f30;;
    font-size: 18px;
  }
}

.info-content {
  .content {
    white-space: pre-line;
  }
  .note {
    margin-top: 20px;
    font-size: 12px;
    b {
      font-weight: bolder;
      color: #f30;
    }
  }
}

.info-imgs {
  ul {
    list-style: none;
    padding: 0px;
  }
  img {
    margin: 10px 0 5px 0;
    height: auto;
    max-width: 600px;
  }
}

.item {
  margin-bottom: 35px;
  h2 {
    font-weight: bold;
    font-size: 14px;
    color: #333;
  }
  span {
    font-size: 14px
  }
}

.horizontal {
    display: block;
    height: 1px;
    width: 100%;
    margin: 10px 0 20px 0;
    background-color: #dcdfe6;
}

.qr-text {
    background-color: #f87a19;
    color: #fff;
    font-weight: bold;
    padding: 3px 10px 3px 10px;
    display: inline!important;
}

.qr-code {
  text-align: center;
  i {
    font-size: 28px;
  }
  img {
    width: 165px;
    height: 165px;
    padding: 5px;
  }
}

.status-2 {
  background-image: url(~@/assets/img/information/tuijian.gif)
}

.status--2 {
  background-image: url(~@/assets/img/information/quxiao.gif)
}
</style>
