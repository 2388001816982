export const makeId = (length) => {
    const idLength = length || 6;
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < idLength; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
};
export const isUndefinedOrNullOrWhitespace = (input) => {
    if (!input) {
        return true;
    }
    else {
        const strVal = String(input);
        return strVal.trim().length <= 0;
    }
};
