<template>
    <div>
        <ul class="w-list-v">
            <li v-for="info in list" :key="info.id" class="info-list-row">
                <info-row :info="info"/>
            </li>
        </ul>
        <div class="pager">
            <pagination
              v-if="isPagination"
              :total="total"
              :perPage="15"
              :middleSize="10"
             />
        </div>
    </div>
</template>

<script>
import InfoRow from '@/components/InfoRow.vue'
import Pagination from '@/components/Pagination.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    InfoRow,
    Pagination
  },
  props: ['list', 'total', 'isPagination']
}
</script>

<style lang="less" scoped>
.info-list-row {
    padding: 15px;
    border-top: solid 1px #dcdfe6;
}

.pager {
    margin: 25px 0px 10px 0px;
}

</style>
