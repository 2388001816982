import { render, staticRenderFns } from "./RegisterPage.vue?vue&type=template&id=02e54a08&scoped=true"
import script from "./RegisterPage.vue?vue&type=script&lang=js"
export * from "./RegisterPage.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "02e54a08",
  "29b10874"
  
)

export default component.exports