import User from '@/views/layout/User.vue';
import HomePage from '@/views/member/HomePage.vue';
import MsgListPage from '@/views/member/MsgListPage.vue';
import RecordListPage from '@/views/member/RecordListPage.vue';
export default [
    {
        path: '/m/:userid',
        component: User,
        children: [
            { path: 'msgs', component: MsgListPage },
            { path: 'records', component: RecordListPage },
            { path: '*', component: HomePage, name: 'home' }
        ]
    }
];
