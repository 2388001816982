<template>
    <div class="w-card article">
        <div class="article-title">
            <h1>{{ article.title }}</h1>
        </div>
        <div class="article-sub">
            <span v-if="article.author">{{article.author}}</span>
            <span>{{article.updated_at | wTime('yyyy-MM-dd')}}</span>
            <span v-if="article.display>0">展示 {{article.display | wCount}}</span>
            <span v-if="article.hit>0">浏览 {{article.hit | wCount}}</span>
        </div>
        <div class="article-introduction" v-if="article.introduction !== ''">
            {{article.introduction}}
        </div>
        <div v-html="article.content" class="article-content"></div>
    </div>
</template>

<script>

export default {
  props: {
    article: { type: Object, required: true }
  }
}
</script>

<style lang="less" scoped>
.article {
    padding: 15px;
}
.article-title {
    font-weight: 600;
    font-synthesis: style;
    color: #1a1a1a;
    margin-top: 12px;

    h1{
        font-size: 24px;
    }
}

.article-sub {
    margin: 18px 0;
    font-size: 13px;
    span {
        margin-right: 10px;
    }
}

.article-introduction {
    border: 1px solid #dddddd82;
    padding: 8px;
    margin-bottom: 20px;
}
</style>
