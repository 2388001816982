import { render, staticRenderFns } from "./Pagination.vue?vue&type=template&id=41aa281c&scoped=true"
import script from "./Pagination.vue?vue&type=script&lang=js"
export * from "./Pagination.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./Pagination.vue?vue&type=style&index=0&id=41aa281c&prod&lang=less&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "41aa281c",
  "7ff83640"
  
)

export default component.exports