import Vue from 'vue';
import Vuex from 'vuex';
import global from './modules/global';
import aFaq from '@/store/modules/about-faq';
import aAbout from '@/store/modules/about-about';
import aAnnounce from '@/store/modules/about-announce';
import aSiteMap from '@/store/modules/about-site-map';
import aFlink from '@/store/modules/about-flink';
import artIndex from '@/store/modules/article-index';
import artList from '@/store/modules/article-cat-list';
import artDetail from '@/store/modules/article-detail';
import mInfo from '@/store/modules/main-info';
import mIndex from '@/store/modules/main-index';
import mSearch from '@/store/modules/main-search';
import mCitySelection from '@/store/modules/main-city-selection';
import mSpace from '@/store/modules/main-space';
Vue.use(Vuex);
export default function () {
    return new Vuex.Store({
        modules: {
            global: global,
            about: {
                namespaced: true,
                modules: {
                    faq: aFaq,
                    about: aAbout,
                    announce: aAnnounce,
                    site_map: aSiteMap,
                    flink: aFlink
                }
            },
            article: {
                namespaced: true,
                modules: {
                    index: artIndex,
                    cat_list: artList,
                    detail: artDetail
                }
            },
            main: {
                namespaced: true,
                modules: {
                    info: mInfo,
                    index: mIndex,
                    search: mSearch,
                    city_selection: mCitySelection,
                    space: mSpace
                }
            }
        }
    });
}
