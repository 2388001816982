import { createApp } from './main'

const { app, router, store } = createApp({ url: location.pathname })

router.onReady(() => {
  if (window.__INITIAL_STATE__) {
    store.replaceState(window.__INITIAL_STATE__)
  }
  app.$mount('#app')
})
