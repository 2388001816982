<template>
  <div>
     store album page {{$route.params}}
  </div>
</template>

<script>
export default {
}
</script>

<style lang="less" scoped>
</style>
