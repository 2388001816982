import { getFaq, getFaqList, DFT_FAQ_ID } from '@/api/about';
const state = () => ({
    list: [],
    faq: { title: '', content: '' }
});
// getters
const getters = {
    list: (state) => state.list,
    faq: (state) => state.faq
};
// actions
const actions = {
    async getFaq(context, id) {
        const { data } = await getFaq(id || DFT_FAQ_ID);
        context.commit('saveFaq', data.faq);
    },
    async getList(context) {
        const { data } = await getFaqList();
        context.commit('saveList', data.list);
    }
};
// mutations
const mutations = {
    'saveFaq'(state, faq) {
        state.faq = faq;
    },
    'saveList'(state, list) {
        state.list = list;
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
