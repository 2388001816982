import { render, staticRenderFns } from "./IndexHotCities.vue?vue&type=template&id=6a80434a&scoped=true"
import script from "./IndexHotCities.vue?vue&type=script&lang=js"
export * from "./IndexHotCities.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./IndexHotCities.vue?vue&type=style&index=0&id=6a80434a&prod&lang=less&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "6a80434a",
  "8860922a"
  
)

export default component.exports