import Vue from 'vue';
import { logGlobalError } from '@/utils/stats';
import { isMobile } from '@/utils/clientEnv';
import { Loading } from 'element-ui';
import settings from '@/core/settings';
export default Vue.extend({
    created: function () {
        logGlobalError(); // 监听全局异常
    },
    mounted: function () {
        if (settings.isDev())
            return;
        if (!isMobile)
            return;
        const loading = Loading.service({ fullscreen: true, lock: true, text: '您是手机用户，正在转到手机站...' });
        setTimeout(() => {
            loading.close();
            const href = location.href.replace(settings.wUrl(), settings.mUrl());
            if (href !== location.href) {
                location.href = href;
            }
        }, 2000);
    }
});
