import { render, staticRenderFns } from "./ArticleIndexLatestInfo.vue?vue&type=template&id=1a9d8411&scoped=true"
import script from "./ArticleIndexLatestInfo.vue?vue&type=script&lang=js"
export * from "./ArticleIndexLatestInfo.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./ArticleIndexLatestInfo.vue?vue&type=style&index=0&id=1a9d8411&prod&lang=less&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "1a9d8411",
  "2e508b3f"
  
)

export default component.exports