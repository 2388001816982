import Cookies from 'js-cookie';
import { makeId } from '@/utils/string';
import { formatTime, parse } from '@/utils/time';
export const getCid = () => {
    let cid = Cookies.get('cid');
    if (!cid) {
        cid = 'w' + makeId(4) + '_' + formatTime(new Date(), 'yyyyMMddhhmmss'); // 与getCidDate中格式相同
        // Note that if you set a date past 2038 in 32-bit PHP, the number will wrap around and you'll
        // get a cookie that expires instantly. 暂时设置10年后过期
        const expires = 10 * 365 * 24 * 60 * 60;
        Cookies.set('cid', cid, { expires });
    }
    return cid;
};
export const getCidDate = () => {
    const s = getCid().substr(6, 14);
    const d = parse(s, 'yyyyMMddhhmmss'); // 与_checkCid中格式相同
    return d || new Date();
};
