import { render, staticRenderFns } from "./SearchArticle.vue?vue&type=template&id=51f58b7d&scoped=true"
import script from "./SearchArticle.vue?vue&type=script&lang=js"
export * from "./SearchArticle.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "51f58b7d",
  "b92c1960"
  
)

export default component.exports