import { getPublicInfo } from '@/api/user';
import { userPosts } from '@/api/info';
const state = () => ({
    user: {},
    list: []
});
// getters
const getters = {
    user: (state) => state.user,
    list: (state) => state.list
};
// actions
const actions = {
    async getUserInfo(context, userid) {
        const { data } = await getPublicInfo(userid);
        context.commit('saveUserInfo', data.user);
    },
    async getUserPosts(context, userid) {
        const { data } = await userPosts(userid);
        context.commit('saveInfoList', data.list);
    }
};
// mutations
const mutations = {
    'saveUserInfo'(state, user) {
        state.user = user;
    },
    'saveInfoList'(state, list) {
        state.list = list;
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
