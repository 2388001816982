<template>
  <div>
    <breadcrumb :items="breadcrumbItems" :city="settings.city"/>
    <div class="row1 w-card">
      <search-cat-sel />
      <search-region-sel />
      <search-cat-info/>
    </div>

    <div class="row2 w-card">
      <search-info-list
        :list="data.infoList"
        :total="data.total"
        :isPagination="true"
      />
    </div>

    <div class="row3">
      <search-cat-of-hot-cities />
      <friend-links :links="flinks"/>
    </div>
  </div>
</template>

<script>
import SearchCatOfHotCities from '@/views/main/components/SearchCatOfHotCities.vue'
import SearchCatSel from '@/views/main/components/SearchCatSel.vue'
import SearchRegionSel from '@/views/main/components/SearchRegionSel.vue'
import SearchInfoList from '@/views/main/components/SearchInfoList.vue'
import SearchCatInfo from '@/views/main/components/SearchCatInfo.vue'
import FriendLinks from '@/components/FriendLinks.vue'
import Breadcrumb from '@/components/Breadcrumb'
import * as api from '@/api/info'
import { getFLinks } from '@/api/about'
import { getCity } from '@/api/region'
import { parseParamsFromUrl, buildUrl } from '@/utils/link'
import { isEmpty } from '@/utils/object'
import { mapGetters } from 'vuex'

export default {
  components: {
    SearchCatOfHotCities,
    SearchCatSel,
    SearchRegionSel,
    SearchInfoList,
    SearchCatInfo,
    FriendLinks,
    Breadcrumb
  },
  data () {
    return { breadcrumbItems: [] }
  },
  computed: {
    ...mapGetters({
      settings: 'main/search/settings',
      selection: 'main/search/selection',
      paras: 'main/search/paras',
      cat: 'main/search/cat',
      regionName: 'main/search/regionName',
      flinks: 'main/search/flinks',
      data: 'main/search/data'
    })
  },
  watch: {
    '$route' (to, from) {
      this.loadFromPath()
    }
  },
  serverPrefetch () {
    return this.loadFromPath()
  },
  mounted: function () {
    this.loadFromPath()
  },
  methods: {
    loadFromPath () {
      const params = Object.assign({ page: 1 }, parseParamsFromUrl(this.$route.path))
      params.city_dir = this.$route.params.city_dir || ''
      return Promise.all([
        this.$store.dispatch('main/search/loadSettingsFromPath', params),
        this.$store.dispatch('main/search/getData', params),
        this.$store.dispatch('main/search/getFlinks', params),
        this.$store.dispatch('main/search/getCat', params.catid || 0)
      ]).then(() => {
        this.breadcrumbItems = this.getBreadcrumbItems()
      })
    },
    getBreadcrumbItems () {
      if (this.$route.name !== 'mainCategory' || !this.paras.catid) return []
      const sel = this.selection
      let items = [{
        name: (sel.cityname || '') + sel.bigCat.name,
        url: buildUrl('category', { catid: sel.bigCat.id })
      }]
      if (!sel.curCatIsBigCat) {
        const item = {
          name: (sel.areaname || '') + (sel.streetname || '') + this.cat.name,
          url: buildUrl('category', {
            catid: this.cat.id,
            cityid: this.settings.city.id || null,
            areaid: sel.area ? sel.area.id : null,
            streetid: sel.street ? sel.street.id : null
          })
        }
        items.push(item)
      }
      return items
    }
  },
  metaInfo () {
    const name = this.regionName
    const page = this.paras.page ? '-第' + this.paras.page + '页' : ''
    if (this.$route.name === 'mainCategory') {
      return {
        title: name + this.cat.title + '-' + this.s.PROD_NAME + page,
        meta: [
          { name: 'keywords', content: name + this.cat.keywords },
          { name: 'description', content: name + this.cat.description },
          { name: 'mobile-agent', content: 'format=html5; url=' + this.s.mUrl() + this.$route.fullPath }
        ]
      }
    } else if (this.$route.name && this.$route.name.indexOf('mainSearch') !== -1) {
      const keywords = this.paras.keywords || ''
      return {
        title: name + keywords + '-信息搜索' + this.s.PROD_NAME + page,
        meta: [
          { name: 'keywords', content: name + keywords + '，' + '服装行业，信息搜索' },
          { name: 'description', content: name + keywords + '，' + '服装行业信息搜索结果列表' },
          { name: 'mobile-agent', content: 'format=html5; url=' + this.s.mUrl() + this.$route.fullPath }
        ]
      }
    }
  }
}
</script>

<style lang="less" scoped>
.row1 {
  margin-top: 10px;
  padding: 15px;
}
</style>
