<template>
  <div class="login-panel">
    <div class="login-box">
      <div class="login-icon">
        <i class="el-icon-user-solid"></i>
      </div><br/>
      Hi, 你好<br/>
      <a :href="fz8cUrl()" class="btn login-btn w-qrcode">一键登录
        <img style="display: none" src="@/assets/img/fz8c_app.png" alt="服装八场手机App"/>
      </a><br/>
      <a :href="fz8cUrl()" class="btn register-btn w-qrcode">免费注册
        <img style="display: none" src="@/assets/img/fz8c_app.png" alt="服装八场手机App"/>
      </a>
      <table class="ad-icons">
        <tbody>
          <tr>
            <td rowspan="2"><i class="iconfont icon-textfree"></i></td>
            <td>免费发布</td>
          </tr>
          <tr>
            <td>免费入住</td>
          </tr>
          <tr>
            <td rowspan="2"><i class="iconfont icon-yonghuduoge"></i></td>
            <td>大量用户</td>
          </tr>
          <tr>
            <td>海量信息</td>
          </tr>
          <tr>
            <td rowspan="2"><i class="iconfont icon-shijiandidianiconfontditudingwei"></i></td>
            <td>随时随地</td>
          </tr>
          <tr>
            <td>搞定生意</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="banner-ads">
      <a :href="fz8cUrl()" title="关注公众号">
        <img src="@/assets/img/ad_mp.png" :alt="s.PROD_NAME+'公众号'"/>
      </a>
      <a :href="fz8cUrl()" title="下载App">
        <img src="@/assets/img/ad_app.png" :alt="s.PROD_NAME+'APP'"/>
      </a>
    </div>
  </div>
</template>

<script>
import { fz8cUrl } from '@/utils/link'

export default {
  methods: {
    fz8cUrl
  }
}
</script>

<style lang="less" scoped>
.login-box {
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  border: #ddd 1px solid;
  padding: 10px 0px 10px 0px;
  .login-icon {
    width: 60px;
    height: 60px;
    background-color: #ddd;
    border-radius: 50%;
    text-align: center;
    .el-icon-user-solid {
      font-size: 50px;
      color: #aaa;
    }
  }
  .btn {
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 120px;
    border-radius: 4px;
    border: #ffebe7 solid 1px;
    font-weight: bolder;
  }
  .login-btn {
    background-color: #ffebe7;
    color: #ff3a00;
    margin-top: 10px;
  }
  .register-btn {
    background-color: #fff;
    color: #ff3a00;
  }
  .ad-icons {
    padding-top: 20px;
    font-size: 14px;
    .iconfont {
      font-size: 30px;
      color: #ffffff;
      background-color: #ff5b00;
      border-radius: 4px;
    }
  }
}

.banner-ads {
  width: 190px;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  img {
    display: block;
    width: 190px;
    margin-top: 7px;
  }
}
</style>
