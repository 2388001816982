import { render, staticRenderFns } from "./HomePage.vue?vue&type=template&id=46ae5094&scoped=true"
import script from "./HomePage.vue?vue&type=script&lang=js"
export * from "./HomePage.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "46ae5094",
  "fa42d922"
  
)

export default component.exports