<template>
    <table>
      <tbody>
        <tr><td>route</td><td></td></tr>
        <tr><td>path</td><td>{{$route.path}}</td></tr>
        <tr><td>name</td><td>{{$route.name}}</td></tr>
        <tr><td>hash</td><td>{{$route.hash}}</td></tr>
        <tr><td>query</td><td>{{$route.query}}</td></tr>
        <tr><td>params</td><td>{{$route.params}}</td></tr>
        <tr><td>fullPath</td><td>{{$route.fullPath}}</td></tr>
        <tr><td>redirectedFrom</td><td>{{$route.redirectedFrom}}</td></tr>
        <tr><td>meta</td><td>{{$route.meta}}</td></tr>
        <tr><td>matched</td>
        <td>
          <ul>
            <li v-for="(m, i) in $route.matched" :key="i">
              path: {{m.path}}<br/>
              regex: {{m.regex}}<br/>
              name: {{m.name}}<br/>
              components: <pre>{{m.components}}</pre>
            </li>
          </ul>
        </td>
        </tr>
      </tbody>
    </table>
</template>

<script>
export default {
  name: 'routeDetails'
}
</script>

<style lang="less" scoped>
</style>
