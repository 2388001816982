<template>
    <div class="w-cat-page-filter">
         <h2 class="w-cat-page-filter-name">搜索：</h2>
         <input v-model="keywords" class="seach-input" placeholder="输入关键词，抓住好商机"/>
         <button class="seach-button" @click="seach()">搜本类</button>
    </div>
</template>

<script>
import { buildUrl, parseParams } from '@/utils/link.ts'

export default {
  data: () => {
    return {
      keywords: ''
    }
  },
  methods: {
    buildUrl,
    seach () {
      if (this.keywords === '') {
        return
      }
      const params = parseParams(this.$route.params.PARA_TEXT)
      delete params['page']
      const url = buildUrl('search', Object.assign(params, { keywords: this.keywords }))
      this.$router.push(url)
      this.keywords = ''
    }
  }
}
</script>

<style lang="less" scoped>
.seach-input {
    border: 1px solid #d4d4d4;
    height: 22px;
    padding-left: 5px;
    padding-right: 5px;
    width: 250px;
}

.seach-button {
    margin-left: 5px;
    width: 68px;
    border: 0;
    background: #ff942c;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
}

</style>
