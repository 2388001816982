import { formatTime } from '@/utils/time';
export const getInstantData = (input) => {
    return { a: input + ' instant' };
};
export const getTimeoutData = (input) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const t = formatTime((new Date()), 'hh:mm:ss:SSS');
            const data = { a: input + ' ' + t + ' after 1 second' };
            resolve(data);
        }, 2000);
    });
};
export const getPromiseResolveData = (input) => {
    return new Promise((resolve, reject) => {
        const data = { a: input + ' resolve promise' };
        resolve(data);
    });
};
export const getPromiseRejectData = (input) => {
    return new Promise((resolve, reject) => {
        const data = { a: input + ' rejected promise' };
        reject(data);
    });
};
