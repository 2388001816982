import { render, staticRenderFns } from "./IndexHotInfoList.vue?vue&type=template&id=1d507b04&scoped=true"
import script from "./IndexHotInfoList.vue?vue&type=script&lang=js"
export * from "./IndexHotInfoList.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./IndexHotInfoList.vue?vue&type=style&index=0&id=1d507b04&prod&lang=less&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "1d507b04",
  "7a7bfa0e"
  
)

export default component.exports