<template>
    <div class="info w-card">
        <ul>
          <li v-for="info in latestInfo" :key="info.id">
            <i class="iconfont icon-point-copy"></i>
            <router-link :to="makeInfoUrl(info)" :title="info.region_name+' - '+info.content">
              {{info.title}}
            </router-link>
          </li>
        </ul>
    </div>
</template>

<script>
import { makeInfoUrl } from '@/utils/link'

export default {
  props: ['latestInfo'],
  methods: {
    makeInfoUrl
  }
}
</script>

<style lang="less" scoped>
.info {
  font-size: 12px;
  padding: 10px 0;
  ul {
    padding: 0;
    margin: 0;
  }
  li {
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 150px;
    padding: 5px 0px 5px 10px;
  }
}

.iconfont {
  font-size: 10px;
}

</style>
