import { render, staticRenderFns } from "./InfoCatOfInterest.vue?vue&type=template&id=01db28b2&scoped=true"
import script from "./InfoCatOfInterest.vue?vue&type=script&lang=js"
export * from "./InfoCatOfInterest.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./InfoCatOfInterest.vue?vue&type=style&index=0&id=01db28b2&prod&lang=less&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "01db28b2",
  "22203332"
  
)

export default component.exports