<template>
  <div class='w-page'>
    <about-navigation :path="$route.path"/>
    <div>
      <div class="w-title">
        <h1>{{ announce.title }}</h1>
      </div>
      <div class="w-content" v-html="announce.content" />
    </div>
    <div>
      <div class="w-content announce-list">
        <h1>公告列表</h1>
        <div v-for="a in list" :key="a.id" class="w-list-row">
          <div class="w-list-row-content">
          <router-link :to="makeAnnounceUrl(a)" :title="a.title">{{ a.title }}</router-link>
          </div>
          <div class="w-list-row-date">
            <span>{{ a.created_at | wTime('yyyy年MM月dd日') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AboutNavigation from '@/views/main/about/components/AboutNavigation.vue'
import { makeAnnounceUrl, parseCityDir } from '@/utils/link'
import { mapGetters } from 'vuex'

export default {
  name: 'announce',
  components: { AboutNavigation },
  computed: {
    ...mapGetters({
      announce: 'about/announce/announce',
      list: 'about/announce/list'
    })
  },
  watch: {
    '$route' (to, from) {
      this.$store.dispatch('about/announce/getAnnounce', this.$route.params.id || 0)
    }
  },
  serverPrefetch () {
    return this.fetchData()
  },
  mounted: function () {
    this.fetchData()
  },
  methods: {
    makeAnnounceUrl,
    fetchData () {
      const city_dir = parseCityDir(this.$route.path)
      return Promise.all([
        this.$store.dispatch('about/announce/getAnnounce', this.$route.params.id || 0),
        this.$store.dispatch('about/announce/getList', city_dir)
      ])
    }
  },
  metaInfo () {
    return {
      title: this.s.PROD_NAME + '-网站公告 ' + this.announce.title,
      meta: [
        { name: 'keywords', content: this.announce.title + '，网站公告，' + this.s.PROD_NAME },
        { name: 'description', content: this.s.PROD_NAME + '网站公告频道，' + this.announce.title }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.announce-list h1 {
    margin-bottom: 20px;
}
</style>
