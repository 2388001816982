import * as infoApi from '@/api/info';
const state = () => ({
    cats: []
});
// getters
const getters = {
    cats: (state) => state.cats
};
// actions
const actions = {
    async getCats(context) {
        const { data } = await infoApi.getCats();
        context.commit('saveCats', data);
    }
};
// mutations
const mutations = {
    'saveCats'(state, cats) {
        state.cats = cats;
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
