import * as api from '@/api/info';
const state = () => ({
    foci: [],
    hot_info_list: [],
    cat_info_list: [],
    hot_cities: [],
    articles: [],
    announces: [],
    flinks: []
});
// getters
const getters = {
    foci: (state) => state.foci,
    hot_info_list: (state) => state.hot_info_list,
    cat_info_list: (state) => state.cat_info_list,
    hot_cities: (state) => state.hot_cities,
    articles: (state) => state.articles,
    announces: (state) => state.announces,
    flinks: (state) => state.flinks
};
// actions
const actions = {
    async getIndexData(context, city_dir) {
        const { data } = await api.getIndexData(city_dir);
        context.commit('saveAnnounces', data.announces);
        context.commit('saveArticles', data.articles);
        context.commit('saveFoci', data.focus);
        context.commit('saveFlinks', data.foreign_links);
        context.commit('saveHotCities', data.hot_cities);
        context.commit('saveHotInfoList', data.hot_info_list);
    },
    async getCatInfoList(context, city_dir) {
        const { data: { list } } = await api.getIndexCatInfoList(city_dir);
        context.commit('saveBigCatInfoList', list);
    }
};
// mutations
const mutations = {
    'saveAnnounces'(state, list) {
        state.announces = list;
    },
    'saveArticles'(state, list) {
        state.articles = list;
    },
    'saveFoci'(state, list) {
        state.foci = list;
    },
    'saveFlinks'(state, list) {
        state.flinks = list;
    },
    'saveHotCities'(state, list) {
        state.hot_cities = list;
    },
    'saveHotInfoList'(state, list) {
        state.hot_info_list = list;
    },
    'saveBigCatInfoList'(state, list) {
        state.cat_info_list = list;
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
