import { render, staticRenderFns } from "./AboutNavigation.vue?vue&type=template&id=d0a313b6&scoped=true"
import script from "./AboutNavigation.vue?vue&type=script&lang=js"
export * from "./AboutNavigation.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./AboutNavigation.vue?vue&type=style&index=0&id=d0a313b6&prod&lang=less&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "d0a313b6",
  "bdc3a8a8"
  
)

export default component.exports