import Vue from 'vue';
import VueRouter from 'vue-router';
import memberRoutes from '@/router/member';
import mainRoutes from '@/router/main';
import Main from '@/views/layout/Main.vue';
import IndexPage from '@/views/main/IndexPage.vue';
import { configGA } from '@/utils/stats';
Vue.use(VueRouter);
const prepareRoutes = function () {
    const baseRoutes = [];
    // 注: 任意路由必须在最后，否则任意路由之后的路由不工作
    const anyRoutes = [{ path: '/', component: Main, children: [{ path: '', alias: '/*', component: IndexPage }] }];
    const routes = baseRoutes.concat(memberRoutes, mainRoutes, anyRoutes);
    return routes;
};
const handleScroll = function (router) {
    /** 每当路由变化时滚动条回到顶部， 如果使用了位置的标识符'#p'则不执行 */
    if (process.client) {
        router.afterEach(() => {
            if (location.hash.indexOf('#p') === -1) {
                document.body.scrollTo(0, 0);
            }
        });
    }
};
export default () => {
    const router = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes: prepareRoutes()
    });
    handleScroll(router);
    configGA(router);
    return router;
};
