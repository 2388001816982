import { removeToken } from '@/utils/token';
import request from '@/utils/request';
import { CITY_GLOBAL as GLOBAL_CITY, parseCityDir } from '@/utils/link';
import { getCity } from '@/api/region';
const state = () => ({
    user: {},
    cid: {},
    city: GLOBAL_CITY,
    location: {}
});
// mutation types
const USER_UPDATE = 'USER_UPDATE';
const USER_REMOVE = 'USER_REMOVE';
const CITY_UPDATE = 'CITY_UPDATE';
const LOCATION_UPDATE = 'LOCATION_UPDATE';
// getters
const getters = {
    user: (state) => state.user,
    cid: (state) => state.cid,
    logged: (state) => state.user.userid !== undefined,
    city: (state) => state.city,
    location: (state) => state.location
};
// actions
const actions = {
    loginUser(context, user) {
        context.commit(USER_UPDATE, user);
    },
    logoutUser(context) {
        context.commit(USER_REMOVE);
    },
    refreshUser(context) {
        const paras = { cid: getters.cid };
        return new Promise((resolve, reject) => {
            request({
                url: 'auth/refresh',
                method: 'post',
                data: paras
            }).then((res) => {
                if (res.data.error) {
                    context.commit(USER_REMOVE);
                    removeToken();
                }
                else {
                    context.commit(USER_UPDATE, res.data.user);
                }
                resolve(res.data);
            })
                .catch((err) => {
                context.commit(USER_REMOVE);
                removeToken();
                resolve(err);
            });
        });
    },
    setCity(context, city) {
        context.commit(CITY_UPDATE, city);
    },
    // 根据拼音同步城市
    syncCityFromUrl(context, url) {
        return new Promise((resolve, reject) => {
            const city_dir = parseCityDir(url);
            // 没有dir，则设置全国
            if (!city_dir) {
                context.commit(CITY_UPDATE, GLOBAL_CITY);
                return resolve(GLOBAL_CITY);
            }
            // 城市相同，则直接返回
            if (city_dir === context.state.city.dir) {
                return resolve(context.state.city);
            }
            getCity({ city_dir }).then((res) => {
                if (res.data.error) {
                    reject(res.data.error);
                }
                const city = res.data.city;
                context.commit(CITY_UPDATE, city);
                resolve(city);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    setLocation(context, location) {
        context.commit(LOCATION_UPDATE, location);
    }
};
// mutations
const mutations = {
    [USER_UPDATE](state, user) {
        state.user = user;
        state.city = user.city;
    },
    [USER_REMOVE](state) {
        state.user = {};
    },
    [CITY_UPDATE](state, city) {
        state.city = city;
    },
    [LOCATION_UPDATE](state, location) {
        state.location = location;
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
