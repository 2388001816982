<template>
  <router-link :to="makeAnnounceUrl(announce)">
    <div class="w-row">
      <div class="w-row-left" v-if="displayTime">
        <span>{{ announce.created_at | wTime('MM月dd日') }}</span>
      </div>
      <div class="w-row-center">
        <span>
          {{ announce.title }}
        </span>
      </div>
    </div>
  </router-link>
</template>

<script>
import { makeAnnounceUrl } from '@/utils/link'

export default {
  props: {
    announce: { type: Object, required: true },
    displayTime: { type: Boolean, default: true }
  },
  methods: {
    makeAnnounceUrl
  }
}
</script>

<style lang="less" scoped>
 .w-row-left {
    flex: 0 0 65px;
 }
</style>
