import * as api from '@/api/info';
import { getFLinks } from '@/api/about';
import { isEmpty } from '@/utils/object';
const state = () => ({
    settings: { cats: [] },
    selection: {}, // 包含paras之外，还包含 catname, cityname ....
    paras: {}, // 纯参数，包含 catid, cityid, areaid ...
    data: { infoList: [], total: 0 },
    cat: api.DEFAULT_CAT,
    flinks: [],
    breadcrumbItems: []
});
// getters
const getters = {
    settings: (state) => state.settings,
    selection: (state) => state.selection,
    paras: (state) => state.paras,
    data: (state) => state.data,
    cat: (state) => state.cat,
    flinks: (state) => state.flinks,
    regionName: (state) => {
        let name = state.selection.cityname || '';
        name += state.selection.areaname || '';
        name += state.selection.streetname || '';
        return name;
    }
};
// actions
const actions = {
    async loadSettingsFromPath(context, params) {
        const { data } = await api.searchSettings({ city_dir: params.city_dir || '' });
        context.commit('saveSettings', data);
        context.commit('saveParas', params);
        context.dispatch('parepareSelection', { settings: data, selection: params });
    },
    async getData(context, params) {
        const { data } = await api.search(params);
        context.commit('saveData', data);
    },
    async getCat(context, catid) {
        if (!catid) {
            return context.commit('saveCat', api.DEFAULT_CAT);
        }
        const { data } = await api.getCat(catid);
        context.commit('saveCat', data);
    },
    async getFlinks(context, params) {
        const key = 'Search_page_flinks_' + params.city_dir + '_' + params.catid;
        const { data } = await getFLinks({
            city_dir: params.city_dir || '',
            catid: params.catid,
            place: 'www_cat'
        });
        context.commit('saveFlinks', data);
    },
    parepareSelection(context, params) {
        // 记录选中分类和区域
        const s = Object.assign({}, params.selection);
        const settings = params.settings;
        s.cityname = settings.city && settings.city.id !== 0 ? settings.city.name : '';
        if (!s.catid) {
            s.cats = settings.cats;
        }
        else {
            settings.cats.forEach((cat) => {
                if (cat.id === s.catid) {
                    s.cats = cat.children;
                    s.bigCat = cat;
                    s.curCatIsBigCat = true;
                }
                else {
                    let flag = false;
                    cat.children.forEach((subCat) => {
                        if (subCat.id === s.catid) {
                            s.cats = cat.children;
                            flag = true;
                            s.curCatIsBigCat = false;
                        }
                    });
                    if (flag) {
                        s.bigCat = cat;
                    }
                }
            });
        }
        if (!isEmpty(settings.areas)) {
            settings.areas.forEach((area) => {
                if (area.id === s.areaid) {
                    settings.streets = area.streets;
                    s.area = area;
                    s.areaname = area.name;
                }
                area.streets.forEach((street) => {
                    if (street.id === s.streetid) {
                        s.street = street;
                        s.streetname = street.name;
                    }
                });
            });
        }
        context.commit('saveSelection', s);
    }
};
// mutations
const mutations = {
    'saveSettings'(state, settings) {
        state.settings = settings;
    },
    'saveParas'(state, paras) {
        state.paras = paras;
    },
    'saveData'(state, data) {
        state.data = data;
    },
    'saveCat'(state, cat) {
        state.cat = cat;
    },
    'saveFlinks'(state, flinks) {
        state.flinks = flinks;
    },
    'saveSelection'(state, s) {
        state.selection = s;
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
