<template>
  <div>
    <el-menu :default-active="defaultIndex"
            mode="horizontal"
            background-color="#45a3fa"
            text-color="#eee"
            active-text-color="#bbb" class="top-nav-menu">
            <el-menu-item v-for="m in top_menus" :key="m.text" :index="m.text" class="pc-menu">
              <router-link :to="makeCityUrl(m.href, userCity.dir)">{{ m.text }}</router-link>
            </el-menu-item>
            <el-submenu index="2" class="pc-menu">
              <template slot="title"><i class="el-icon-menu"/>导航</template>
              <el-menu-item v-for="m in sub_menus" :key="m.text" :index="m.text">
                <router-link :to="makeCityUrl(m.href, userCity.dir)" class="nav-sub-link">{{ m.text }}</router-link>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="3" class="mobile-menu">
              <template slot="title"><i class="el-icon-menu"/>导航</template>
              <el-menu-item v-for="m in top_menus" :key="m.text" :index="m.text">
                <router-link :to="makeCityUrl(m.href, userCity.dir)" class="nav-sub-link">{{ m.text }}</router-link>
              </el-menu-item>
              <el-menu-item v-for="m in sub_menus" :key="m.text" :index="m.text">
                <router-link :to="makeCityUrl(m.href, userCity.dir)" class="nav-sub-link">{{ m.text }}</router-link>
              </el-menu-item>
            </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import { makeCityUrl } from '@/utils/link'

export default {
  data: () => {
    return {
      top_menus: [
        { href: '/index.html', text: '首页' },
        { href: '/category-catid-246.html', text: '外发加工' },
        { href: '/category-catid-264.html', text: '承接加工' },
        { href: '/category-catid-421.html', text: '招标' },
        { href: '/category-catid-367.html', text: '招聘' },
        { href: '/category-catid-299.html', text: '求职' },
        { href: '/category-catid-309.html', text: '设备' },
        { href: '/category-catid-323.html', text: '厂房住房' },
        { href: '/news.html', text: '新闻' }
      ],
      sub_menus: [
        { href: '/category-catid-327.html', text: '批发采购' },
        { href: '/category-catid-338.html', text: '面辅料' },
        { href: '/category-catid-405.html', text: '临时工' },
        { href: '/category-catid-387.html', text: '广告' },
        { href: '/category-catid-386.html', text: '拼车' }
      ]
    }
  },
  computed: {
    defaultIndex () {
      for (const item of this.top_menus) {
        if (item.href === 'index.html') {
          continue
        }
        if (this.$route.fullPath.indexOf(item.href.split('.html')[0]) >= 0) {
          return item.text
        }
      }

      return this.$route.fullPath === 'index.html' ? '首页' : ''
    }
  },
  methods: {
    makeCityUrl
  }
}
</script>

<style lang="less">
/* 如果scoped，则无法应用到el-submenu__title 等样式 */
.top-nav-menu {
  border-bottom: none;
  .pc-menu {
    padding: 0px;
    margin: 0px;
    a {
      display: inline-block;
      padding: 0 20px;
      text-align: center;
    }
  }
  .el-icon-menu,.el-submenu__title,.el-icon-arrow-down {
    color: #fff!important;
  }
}

a.nav-sub-link, a.nav-sub-link:visited {
  color: #eee;
  display: block;
}

@media (min-width: 861px) {
  .top-nav-menu {
    .mobile-menu {
      display: none;
    }
  }
}

@media (max-width: 860px) {
  .top-nav-menu {
    .pc-menu {
      display: none;
    }
  }
}
</style>
