<template>
  <div class="city-body">
    <div class="hot-cities">
      <h3 id="top">
        热门城市
        <span>（按拼音排序）</span>
      </h3>
      <ul class="w-list-h">
        <li>
          <router-link :to="'/'" @click.native="setCity({ id: 0, name: '全国', lon: 114.31, lat: 30.52 })"
          >全国</router-link>
        </li>
        <li
          v-for="c in data.hot_cities"
          :key="c.id"
        >
          <router-link :to="makeCityIndexUrl(c)" @click.native="setCity(c)" :title="c.name+'-'+s.PROD_NAME"
          >{{ c.name }}</router-link>
        </li>
      </ul>
    </div>
    <div class="provinces">
      <h3>
        省份
        <span>（按拼音排序）</span>
      </h3>
      <ul class="w-list-h">
        <li
          v-for="(pCities, p) in data.cities"
          :key="p"
        >
          <a
            class="btn" :href="'#p'+p"
          >{{ p }}</a>
        </li>
      </ul>
    </div>
    <div v-for="(pCities, p) in data.cities"
        :id="'p'+ p"
        :key="p"
        class="province-cities"
      >
      <h4>
          {{ p }}
      </h4>
      <ul class="w-list-h">
        <li v-for="c in pCities"
            :key="c.id">
          <router-link
              class="btn"
              :class="{'ifhot': c.ifhot===1}"
              :to="makeCityIndexUrl(c)"
              @click.native="setCity(c)"
              :title="c.name+'-'+s.PROD_NAME"
            >{{ c.name }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { makeCityIndexUrl } from '@/utils/link'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      data: 'main/city_selection/data'
    })
  },
  serverPrefetch () {
    return this.fetchData()
  },
  mounted: function () {
    return this.fetchData()
  },
  methods: {
    makeCityIndexUrl,
    fetchData () {
      return this.$store.dispatch('main/city_selection/getData')
    },
    setCity (c) {
      // TODO: 目前userCity直接设置，待添加用户功能后参考m3的城市设置
      this.$store.dispatch('global/setCity', c)
    }
  },
  metaInfo () {
    return {
      title: '切换城市-' + this.s.PROD_NAME
    }
  }
}
</script>

<style lang="less" scoped>
.city-body {
  background-color: #fff;
  padding: 10px 0px 40px 0px;
  .hot-cities {
    margin: 10px 10px;
    h3 {
      padding: 10px 0px 10px 0px;
    }
  }
  .provinces {
    margin: 30px 10px 10px 10px;
  }
  .province-cities {
    margin: 10px 10px;
    h4 {
      padding: 8px 0px 8px 0px;
    }
    .ifhot {
      color: red;
      text-decoration: underline;
    }
  }
}

</style>
