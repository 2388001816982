<template>
  <div class="w-list-h">
    <input v-model="paras.mobile" type="text"/>
    <input v-model="paras.password" type="password" />
    <button @click="login()">登录</button>
  </div>
</template>

<script>
import * as api from '@/api/user'

export default {
  data: function () {
    return {
      error: '',
      paras: { mobile: '', password: '' }
    }
  },
  methods: {
    login: function () {
      if (!this.validate()) {
        return
      }

      const self = this
      api.login(this.paras).then(function (res) {
        self.$store.dispatch('loginUser', res.data.user)
        self.$store.dispatch('setToken', res.data.token)
        self.$router.push({ name: 'home', params: { userid: this.user.userid } })
      }).catch((err) => {
        this.error = err
      })
    },
    validate: function () {
      if (this.paras.mobile === '') {
        this.error = '请输入用户名'
        return false
      }
      if (this.paras.password === '') {
        this.error = '请输入密码'
        return false
      }

      return true
    }
  }
}
</script>

<style lang="less" scoped>
</style>
