import { getCatListData } from '@/api/article';
const state = () => ({
    cat_data: {},
    cat: { name: '' },
    latest_info: [],
    latest_articles: {}
});
// getters
const getters = {
    cat_data: (state) => state.cat_data,
    cat: (state) => state.cat,
    latest_info: (state) => state.latest_info,
    latest_articles: (state) => state.latest_articles
};
// actions
const actions = {
    async getData(context, params) {
        const { data } = await getCatListData(params);
        context.commit('saveCatData', data.cat_data);
        context.commit('saveCat', data.cat_data.cat);
        context.commit('saveLatestInfo', data.latest_info);
        context.commit('saveLatestArticle', data.latest_articles);
    }
};
// mutations
const mutations = {
    'saveCatData'(state, cat_data) {
        state.cat_data = cat_data;
    },
    'saveCat'(state, cat) {
        state.cat = cat;
    },
    'saveLatestInfo'(state, latest_info) {
        state.latest_info = latest_info;
    },
    'saveLatestArticle'(state, latest_articles) {
        state.latest_articles = latest_articles;
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
