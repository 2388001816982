import { render, staticRenderFns } from "./WImg.vue?vue&type=template&id=ff67f00c&scoped=true"
import script from "./WImg.vue?vue&type=script&lang=ts"
export * from "./WImg.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "ff67f00c",
  "fa9d52dc"
  
)

export default component.exports