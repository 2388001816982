import { render, staticRenderFns } from "./InfoCatOfHotCities.vue?vue&type=template&id=5634c610&scoped=true"
import script from "./InfoCatOfHotCities.vue?vue&type=script&lang=js"
export * from "./InfoCatOfHotCities.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./InfoCatOfHotCities.vue?vue&type=style&index=0&id=5634c610&prod&lang=less&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5634c610",
  "fb9bb5c4"
  
)

export default component.exports