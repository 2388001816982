<template>
    <div class="w-card">
      <div class="w-list-title">
        <h2>热门信息排行榜</h2>
      </div>
      <ul class="w-list-v">
        <li v-for="info in hotInfo" :key="info.id">
          <simple-info-row :info="info" :displayTime="false"/>
        </li>
      </ul>
    </div>
</template>

<script>
import { makeInfoUrl } from '@/utils/link'
import SimpleInfoRow from '@/components/SimpleInfoRow'

export default {
  props: ['hotInfo'],
  components: {
    SimpleInfoRow
  },
  methods: {
    makeInfoUrl
  }
}
</script>

<style lang="less" scoped>
</style>
