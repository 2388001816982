import Vue from 'vue';
import { formatTime, getDate } from '@/utils/time';
Vue.filter('wTime', (input, format) => {
    if (!input) {
        return '';
    }
    const d = getDate(input);
    if (format) {
        return formatTime(d, format);
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const yearDiff = today.getFullYear() - d.getFullYear();
    if (yearDiff > 0) {
        const monthDiff = today.getMonth() - d.getMonth() + 12 * yearDiff;
        const format = monthDiff > 6 ? 'yy年MM月' : 'MM月dd日';
        return formatTime(d, format);
    }
    else {
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const diffDays = (d.getTime() - today.getTime()) / oneDay;
        if (diffDays >= 0) {
            const now = getDate();
            const diffSeconds = (now.getTime() - d.getTime()) / 1000;
            const diffHours = diffSeconds / 3600;
            if (diffHours >= 1) {
                return diffHours.toFixed(0) + '小时前';
            }
            const diffMins = diffSeconds / 60;
            if (diffMins >= 1) {
                return diffMins.toFixed(0) + '分钟前';
            }
            return diffSeconds > 0 ? diffSeconds.toFixed(0) + '秒前' : '刚刚';
        }
        else if (diffDays >= -1) {
            return '昨天';
        }
        else if (diffDays >= -2) {
            return '前天';
        }
        else if (diffDays >= -7) {
            return Math.abs(Math.floor(diffDays)) + '天前';
        }
        else {
            return formatTime(d, 'MM月dd日');
        }
    }
});
