import Vue from 'vue';
import { mapGetters } from 'vuex';
import settings from '@/core/settings';
export default Vue.extend({
    data: () => {
        return {
            s: settings
        };
    },
    computed: {
        ...mapGetters({
            user: 'global/user',
            logged: 'global/logged',
            layout: 'global/layout',
            userCity: 'global/city',
            userLocation: 'global/location'
        })
    }
});
