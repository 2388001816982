<template>
<!-- TODO：搜索文章 目前不紧急着做 -->
    <div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>

</style>
