import { render, staticRenderFns } from "./Breadcrumb.vue?vue&type=template&id=460cdcac&scoped=true"
import script from "./Breadcrumb.vue?vue&type=script&lang=js"
export * from "./Breadcrumb.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./Breadcrumb.vue?vue&type=style&index=0&id=460cdcac&prod&lang=less&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "460cdcac",
  "6aa4fe6e"
  
)

export default component.exports