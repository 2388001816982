<template>
  <div class="cat w-card">
    <h1 class="w-list-title">{{cat.name}}</h1>
    <ul class="w-list-v">
      <li v-for="(a,index) in catData.list" :key="index">
        <simple-article-row :article="a" :displayTime="true"/>
      </li>
    </ul>
    <div class="pager">
      <pagination
        :total="catData.total"
        :perPage="20"
        :middleSize="6"
       />
    </div>
  </div>
</template>

<script>
import { makeArticleUrl } from '@/utils/link'
import Pagination from '@/components/Pagination.vue'
import SimpleArticleRow from '@/components/SimpleArticleRow.vue'

export default {
  props: ['catData', 'cat'],
  components: {
    Pagination,
    SimpleArticleRow
  },
  methods: {
    makeArticleUrl
  }
}
</script>

<style lang="less" scoped>
.w-list-title {
  height: 40px;
}

.pager {
    margin: 35px 0px 10px 0px;
}
</style>
