import { getAnnounce, getAnnounceList } from '@/api/about';
const state = () => ({
    list: [],
    announce: { title: '', content: '' }
});
// getters
const getters = {
    list: (state) => state.list,
    announce: (state) => state.announce
};
// actions
const actions = {
    async getAnnounce(context, id) {
        const { data } = await getAnnounce(id);
        context.commit('saveAnnounce', data.announce);
    },
    async getList(context, city_dir) {
        const { data } = await getAnnounceList({ city_dir });
        context.commit('saveList', data.list);
    }
};
// mutations
const mutations = {
    'saveAnnounce'(state, announce) {
        state.announce = announce;
    },
    'saveList'(state, list) {
        state.list = list;
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
