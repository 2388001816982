import { render, staticRenderFns } from "./ArticleCatPage.vue?vue&type=template&id=79a7fbc6&scoped=true"
import script from "./ArticleCatPage.vue?vue&type=script&lang=js"
export * from "./ArticleCatPage.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./ArticleCatPage.vue?vue&type=style&index=0&id=79a7fbc6&prod&lang=less&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "79a7fbc6",
  "182285ec"
  
)

export default component.exports