<template>
  <footer>
    <el-row type="flex" justify="center">
      <el-col :span="24" class="footer-links">
        <router-link v-for="(link,index) in footerLinks" :key="index" :to="link.href">
          <i :class="link.icon"/>{{link.html}}</router-link>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="24" class="footer-links">
        <a v-for="(link, index) in certifyLinks" :key="index" :href="link.href" target="_blank">{{link.html}}</a>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="24" class="copy-right">
        ©{{year}} {{s.PROD_NAME}} - 杭州含光科技有限公司
      </el-col>
    </el-row>
  </footer>
</template>

<script>

export default {
  data: () => {
    return {
      footerLinks: [
        { html: '网站帮助', icon: 'el-icon-question', href: '/faq.html' },
        { html: '关于我们', icon: 'el-icon-info', href: '/aboutus.html' },
        { html: '联系我们', icon: 'el-icon-phone', href: '/aboutus-id-3.html' },
        { html: '广告服务', icon: 'el-icon-s-promotion', href: '/aboutus-id-2.html' },
        { html: '友情链接', icon: 'el-icon-connection', href: '/friendlink.html' },
        { html: '网站公告', icon: 'el-icon-reading', href: '/announce.html' },
        { html: '网站地图', icon: 'el-icon-map-location', href: '/sitemap.html' }
      ],
      certifyLinks: [
        { href: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010502000588', html: '浙公网安备 33010502000588号' },
        { href: 'http://www.miibeian.gov.cn/', html: '增值电信业务经营许可证:浙B2-20170660' },
        { href: 'https://beian.miit.gov.cn/', html: '浙ICP备15035957号-2' }
      ]
    }
  },
  computed: {
    year: () => {
      return (new Date()).getFullYear()
    }
  }
}
</script>

<style lang="less" scoped>
.footer-links {
  text-align: center;
  margin-top: 20px;
}
.footer-links a {
  margin-right: 30px;
  color: #eee;
}
.copy-right {
  text-align: center;
  margin-top: 30px;
  color: #eee;
}
</style>
