<template>
  <div>
    <h1>Test page</h1>
    <p>isClient: {{isClient}}</p>
    <p>isServer: {{isServer}}</p>
    <p>process: {{process}}</p>
  </div>
</template>

<script>
import { getInstantData, getTimeoutData, getPromiseResolveData, getPromiseRejectData } from '@/api/test'

export default {
  // 服务端，客户端启动时均会调用。
  // 直接返回vue组件需要的 data
  // 此函数为静态函数，当前组件this指针不可用，只能调用参数内传入的store, route
  // 更多参数参考 https://universal-vue.github.io/docs/reference/
  async asyncData ({ store, route }) {
    /*
    获取数据例子，请根据场景要求自行参考
    参考：
    异步Promise及Async/Await可能最完整入门攻略
    https://blog.csdn.net/m0_37068028/article/details/78509272
    */
    // 例子1 按顺序依次执行，使用请求存在依赖关系的场景
    /*
    try {
      const dataA = await getTimeoutData('a')
      const dataB = await getTimeoutData('b')
      const dataC = await getPromiseRejectData('c') // 更换为getTimeoutData 则成功执行
      return { data: { dataA, dataB, dataC }, error: '' }
    } catch (error) {
      return { error, data: '' }
    }
    */

    // 例子2 同时执行，等待所有结果返回 promise.all
    try {
      const [rA, rB, rC] = await Promise.all([
        getTimeoutData('a'),
        getTimeoutData('b'),
        getPromiseRejectData('c') // 更换为getTimeoutData 则成功执行
      ])
      return { data: { rA, rB, rC }, error: '' }
    } catch (error) {
      return { error, data: '' }
    }

    // 例子3 函数直接返回，无promise
    /*
    return {
      data1: getInstantData('0')
    }
    */

    // 例子4 单个promise调用
    /*
    return getPromiseResolveData('1').then((res) => {
      return {
        data1: {
          res
        }
      }
    })
    */

    // 例子5 多个promise.all调用
    // 注意：需要慎用，仅当所有都是成功才会返回，任何一个失败，就返回失败结果。
    // 理解和使用Promise.all和Promise.race https://www.jianshu.com/p/7e60fc1be1b2
    // 谨慎使用Promise.all https://www.jianshu.com/p/4d03afeee580
    /*
    const p2 = getInstantData('2')
    const p3 = getPromiseResolveData('3')

    // p2, p3都resolve，所以成功
    return Promise.all([p2, p3]).then((result) => {
      return {
        data1: {
          result
        }
      }
    }).catch((error) => {
      console.log(error)
    })
    */

    // 例子6，多个promise.all 调用，其中一个失败，则没有返回。 p6被reject，失败
    /*
    const p4 = getInstantData('4')
    const p5 = getPromiseResolveData('5')
    const p6 = getPromiseRejectData('6')

    return Promise.all([p4, p5, p6]).then((result) => {
      return {
        data1: {
          result
        }
      }
    }).catch((error) => {
      console.log(error)
    })
    */
    // 例子7, 单个promise，await 失败
    /*
    try {
      const { data } = await getPromiseRejectData('单promise reject')
      return {
        data1: data
      }
    } catch (error) {
      return { data1: error }
    }
    */
  },
  // 服务端，客户端均会执行
  beforeCreate () {
  },
  // 服务端，客户端均会执行
  created () {
  },
  // 注意：只有 beforeCreate 和 created 会在服务器端渲染 (SSR) 过程中被调用
  // 其它生命周期函数如， beforeMount, mounted, beforeUpdate, updated, activated, deactivated, beforeDestroy,
  // destroyed 都不会被SSR调用。
  // 避免在 beforeCreate 和 created 生命周期时产生全局副作用的代码，例如timer，可在纯客户端代码内设置timer
  computed: {
    isClient: function () {
      return process.client
    },
    isServer: function () {
      return process.server
    },
    process: function () {
      return process
    }
  }
}
</script>
