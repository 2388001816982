import { render, staticRenderFns } from "./ArticleReadRank.vue?vue&type=template&id=04c46e9c&scoped=true"
import script from "./ArticleReadRank.vue?vue&type=script&lang=js"
export * from "./ArticleReadRank.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "04c46e9c",
  "64e82b9a"
  
)

export default component.exports