import Main from '@/views/layout/Main.vue';
import Shop from '@/views/layout/Shop.vue';
import CitySelPage from '@/views/main/CitySelectionPage.vue';
import IndexPage from '@/views/main/IndexPage.vue';
import LoginPage from '@/views/main/LoginPage.vue';
import RegisterPage from '@/views/main/RegisterPage.vue';
import TestPage from '@/views/main/TestPage.vue';
import InfoPage from '@/views/main/InfoPage.vue';
import SiteMapPage from '@/views/main/about/SiteMapPage.vue';
import AboutPage from '@/views/main/about/AboutPage.vue';
import AnnouncePage from '@/views/main/about/AnnouncePage.vue';
import FaqPage from '@/views/main/about/FaqPage.vue';
import FriendLinkPage from '@/views/main/about/FriendLinkPage.vue';
import SearchPage from '@/views/main/SearchPage.vue';
import SpacePage from '@/views/main/SpacePage.vue';
import ArticleIndexPage from '@/views/main/article/ArticleIndexPage.vue';
import ArticleCatPage from '@/views/main/article/ArticleCatPage.vue';
import ArticlePage from '@/views/main/article/ArticlePage.vue';
import ShopIndexPage from '@/views/main/shop/ShopIndexPage.vue';
import ShopAboutPage from '@/views/main/shop/ShopAboutPage.vue';
import ShopAlbumPage from '@/views/main/shop/ShopAlbumPage.vue';
import ShopContactUsPage from '@/views/main/shop/ShopContactUsPage.vue';
import ShopInfoPage from '@/views/main/shop/ShopInfoPage.vue';
import ShopCatIndexPage from '@/views/main/shop/ShopCatIndexPage.vue';
import ShopCatPage from '@/views/main/shop/ShopCatPage.vue';
const indexPath = 'index.html';
const citiesPath = 'cities.html';
const categoryPath = 'category-:PARA_TEXT.html';
const searchPath = 'search.html';
const searchParaPath = 'search-:PARA_TEXT.html';
const infoPath = 'information-id-:id.html';
const siteMapPath = 'sitemap.html';
const AboutPath = 'aboutus.html';
const aboutIdPath = 'aboutus-id-:id.html';
const announcePath = 'announce.html';
const announceIdPath = 'announce-id-:id.html';
const faqPath = 'faq.html';
const faqIdPath = 'faq-id-:id.html';
const friendLinkPath = 'friendlink.html';
const newsIndexPath = 'news.html';
const newsIdPath = 'news-id-:id.html';
const newsCatPath = 'news-:PARA_TEXT.html';
export default [
    {
        path: '/z:city_dir?',
        component: Main,
        children: [
            // alias 以'/'开头指向一级目录
            { path: indexPath, alias: '/' + indexPath, name: 'mainIndex', component: IndexPage },
            { path: citiesPath, alias: '/' + citiesPath, name: 'mainCitiesSel', component: CitySelPage },
            { path: categoryPath, alias: '/' + categoryPath, name: 'mainCategory', component: SearchPage, meta: { save_position: true, keep_alive: true, cache_url: '' } },
            { path: searchPath, alias: '/' + searchPath, name: 'mainSearch', component: SearchPage, meta: { save_position: true, keep_alive: true, cache_url: '' } },
            { path: searchParaPath, alias: '/' + searchParaPath, name: 'mainSearchPara', component: SearchPage, meta: { save_position: true, keep_alive: true, cache_url: '' } },
            { path: infoPath, alias: '/' + infoPath, name: 'mainInfo', component: InfoPage },
            { path: siteMapPath, alias: '/' + siteMapPath, name: 'mainSiteMap', component: SiteMapPage },
            { path: AboutPath, alias: '/' + AboutPath, name: 'mainAbout', component: AboutPage },
            { path: aboutIdPath, alias: '/' + aboutIdPath, name: 'mainAboutId', component: AboutPage },
            { path: announcePath, alias: '/' + announcePath, name: 'mainAnnounce', component: AnnouncePage },
            { path: announceIdPath, alias: '/' + announceIdPath, name: 'mainAnnounceId', component: AnnouncePage },
            { path: faqPath, alias: '/' + faqPath, name: 'mainFaq', component: FaqPage },
            { path: faqIdPath, alias: '/' + faqIdPath, name: 'mainFaqId', component: FaqPage },
            { path: friendLinkPath, alias: '/' + friendLinkPath, name: 'mainFriendLink', component: FriendLinkPage },
            { path: newsIndexPath, alias: '/' + newsIndexPath, name: 'mainArticleIndex', component: ArticleIndexPage },
            { path: newsIdPath, alias: '/' + newsIdPath, name: 'mainArticle', component: ArticlePage },
            { path: newsCatPath, alias: '/' + newsCatPath, name: 'mainArticleCat', component: ArticleCatPage },
            { path: '', alias: '*', component: IndexPage }
        ]
    },
    {
        path: '/city/:city_dir/',
        component: Main,
        children: [
            { path: categoryPath, alias: '/' + categoryPath, name: 'mainCityCategory', component: SearchPage, meta: { save_position: true, keep_alive: true, cache_url: '' } },
            { path: infoPath, alias: '/' + infoPath, name: 'mainCityInfo', component: InfoPage },
            { path: '', alias: '*', component: IndexPage }
        ]
    },
    {
        path: '/store-:userid?',
        component: Shop,
        children: [
            { path: 'about', name: 'shopAbout', component: ShopAboutPage },
            { path: 'info', name: 'shopInfo', component: ShopInfoPage },
            { path: 'album', name: 'shopAlbum', component: ShopAlbumPage },
            { path: 'contact_us', name: 'shopContactUs', component: ShopContactUsPage },
            { path: '', alias: '*', name: 'shopIndex', component: ShopIndexPage }
        ]
    },
    {
        path: '/store',
        component: Main,
        children: [
            { path: 'cat-:PARA_TEXT', alias: '/corporation-:PARA_TEXT', name: 'shopCat', component: ShopCatPage },
            { path: 'cats', alias: '/corporation.html', name: 'shop', component: ShopCatIndexPage },
            { path: '*', name: 'shopCatIndex', component: ShopCatIndexPage }
        ]
    },
    {
        path: '/u',
        component: Main,
        children: [
            { path: 'space-:userid', alias: '/space/:userid', name: 'space', component: SpacePage },
            { path: 'login', component: LoginPage, name: 'login' },
            { path: 'register', component: RegisterPage, name: 'register' }
        ]
    },
    {
        path: '/test',
        component: Main,
        children: [
            { path: '*', alias: '/testpage', component: TestPage }
        ]
    }
];
