import { render, staticRenderFns } from "./ArticleCatLatestInfo.vue?vue&type=template&id=c7c3da7a&scoped=true"
import script from "./ArticleCatLatestInfo.vue?vue&type=script&lang=js"
export * from "./ArticleCatLatestInfo.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./ArticleCatLatestInfo.vue?vue&type=style&index=0&id=c7c3da7a&prod&lang=less&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "c7c3da7a",
  "3d39f97a"
  
)

export default component.exports