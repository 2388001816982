import { getDetailPageData } from '@/api/article';
const state = () => ({
    latest_info: [],
    latest_articles: {},
    article: {},
    related_articles: {}
});
// getters
const getters = {
    latest_info: (state) => state.latest_info,
    latest_articles: (state) => state.latest_articles,
    article: (state) => state.article,
    related_articles: (state) => state.related_articles
};
// actions
const actions = {
    async getData(context, params) {
        const { data } = await getDetailPageData(params);
        context.commit('saveLatestInfo', data.latest_info);
        context.commit('saveLatestArticles', data.latest_articles);
        context.commit('saveArticle', data.article);
        context.commit('saveRelatedArticles', data.related_articles);
    }
};
// mutations
const mutations = {
    'saveLatestInfo'(state, latest_info) {
        state.latest_info = latest_info;
    },
    'saveLatestArticles'(state, latest_articles) {
        state.latest_articles = latest_articles;
    },
    'saveArticle'(state, article) {
        state.article = article;
    },
    'saveRelatedArticles'(state, related_articles) {
        state.related_articles = related_articles;
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
