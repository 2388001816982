import settings from '@/core/settings';
import { isServer } from '@/utils/clientEnv';
const appendScript = (el) => {
    var s = document.getElementsByTagName('script')[0];
    if (s.parentNode)
        s.parentNode.insertBefore(el, s);
};
const baidu = () => {
    var bp = document.createElement('script');
    var curProtocol = window.location.protocol.split(':')[0];
    if (curProtocol === 'https') {
        bp.src = 'https://zz.bdstatic.com/linksubmit/push.js';
    }
    else {
        bp.src = 'http://push.zhanzhang.baidu.com/push.js';
    }
    appendScript(bp);
};
const so = () => {
    var el = document.createElement('script');
    el.src = 'https://jspassport.ssl.qhimg.com/11.0.1.js?d182b3f28525f2db83acfaaf6e696dba';
    el.id = 'sozz';
    appendScript(el);
};
const discoverPage = () => {
    // 头条和搜狗没有js的链接提交方式
    baidu();
    so();
};
// This is your plugin object. It can be exported to be used anywhere.
const plugin = {
    // The install method is all that needs to exist on the plugin object.
    // It takes the global Vue object as well as user-defined options.
    install(Vue, options) {
        if (!isServer && !settings.isDev()) {
            discoverPage();
        }
    }
};
export default plugin;
