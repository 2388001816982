<template>
  <el-menu class="cat-navs" :unique-opened="true" :collapse="true">
    <el-submenu v-for="bigCat in cats" :key="bigCat.id" :index="bigCat.name">
      <template slot="title">
        <router-link :to="makeInfoCatUrl(bigCat)">
          <i :class="'iconfont ' + bigCat.icon_class"></i> {{bigCat.name}}
        </router-link>
      </template>
      <el-menu-item :index="bigCat.name + '-0'" class="sub-cat">
        <router-link :to="makeInfoCatUrl(bigCat)"><i :class="'iconfont' + bigCat.icon_class"></i> {{bigCat.name}}</router-link>
      </el-menu-item>
      <el-menu-item v-for="cat in getChildCats(bigCat.children)" :key="cat.id" :index="bigCat.name + '-' + cat.name" class="sub-cat">
        <router-link :to="makeInfoCatUrl(cat)">
          <i :class="cat.icon_class"></i>
          {{cat.name}}
        </router-link>
      </el-menu-item>
    </el-submenu>
  </el-menu>
</template>

<script>

import { makeInfoCatUrl } from '@/utils/link'

export default {
  props: ['cats'],
  methods: {
    makeInfoCatUrl,
    getChildCats: function (cats) {
      if (!cats) return null
      if (cats.length <= 1) return null

      return cats
    }
  }
}
</script>

<style lang="less">
/* 注，此文件如果scoped，则无法修改 el-submenu__title 样式 */
.cat-navs {
  width: auto;
  height: 100%;
  border: #ddd 1px solid;
  .el-submenu {
    .el-submenu__title {
      height: 45px;
      line-height: 45px;
    }
  }
}
.sub-cat {
  height: 30px;
  line-height: 30px;
}
</style>
