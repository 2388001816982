import { render, staticRenderFns } from "./SearchInfoList.vue?vue&type=template&id=53a642db&scoped=true"
import script from "./SearchInfoList.vue?vue&type=script&lang=js"
export * from "./SearchInfoList.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./SearchInfoList.vue?vue&type=style&index=0&id=53a642db&prod&lang=less&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "53a642db",
  "3a55dc4a"
  
)

export default component.exports