<template>
  <div class="hot-body">
    <h2>热门信息</h2>
    <ul class="w-list-v">
      <li v-for="(info, i) in list" :key="i" class="hot-info">
        <simple-info-row :info="info" :display-time="false"/>
      </li>
    </ul>
  </div>
</template>

<script>
import SimpleInfoRow from '@/components/SimpleInfoRow'

export default {
  components: {
    SimpleInfoRow
  },
  props: ['list']
}
</script>

<style lang="less" scoped>
.hot-body {
  background-color: #fff;
  border: #ddd 1px solid;
  border-radius: 4px;
  width: 100%;
  h2 {
    padding: 0px 15px 0px 15px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    border-bottom: #ddd 1px solid;
  }
  ul {
    height: 258px;
    overflow-y: auto;
  }
}
</style>
