<template>
  <div>
    <breadcrumb :items="breadcrumbItems"/>
    <div>
       <div class="row1">
         <div class="row1-main">
           <article-detail :article="article"/>
           <related-articles :articles="related_articles.list"/>
         </div>
         <div class="row1-right">
           <search-article />
           <article-cat-latest-info :latestInfo="latest_info"/>
           <article-img-text :latestArticles="latest_articles.list"/>
           <article-read-rank :latestArticles="latest_articles.list"/>
         </div>
       </div>
       <!-- TODO 搜索框 -->
       <div class="row2">
       </div>
    </div>
  </div>
</template>

<script>
import SearchArticle from '@/views/main/article/components/SearchArticle.vue'
import ArticleCatLatestInfo from '@/views/main/article/components/ArticleCatLatestInfo.vue'
import ArticleImgText from '@/views/main/article/components/ArticleImgText.vue'
import ArticleReadRank from '@/views/main/article/components/ArticleReadRank.vue'
import ArticleCatList from '@/views/main/article/components/ArticleCatList.vue'
import RelatedArticles from '@/views/main/article/components/RelatedArticles.vue'
import ArticleDetail from '@/views/main/article/components/ArticleDetail.vue'
import Breadcrumb from '@/components/Breadcrumb'
import { buildUrl } from '@/utils/link'
import * as api from '@/api/article'
import { mapGetters } from 'vuex'

export default {
  components: {
    SearchArticle,
    ArticleCatLatestInfo,
    ArticleImgText,
    ArticleReadRank,
    RelatedArticles,
    ArticleDetail,
    Breadcrumb
  },
  computed: {
    ...mapGetters({
      latest_info: 'article/detail/latest_info',
      latest_articles: 'article/detail/latest_articles',
      article: 'article/detail/article',
      related_articles: 'article/detail/related_articles'
    }),
    breadcrumbItems () {
      const a = this.article
      return [
        { name: '网站新闻', url: 'news.html' },
        { name: a.cat_name, url: buildUrl('news', { catid: a.catid }) }
      ]
    }
  },
  watch: {
    '$route' (to, from) {
      this.fetchData()
    }
  },
  serverPrefetch () {
    return this.fetchData()
  },
  mounted: function () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      const id = this.$route.params.id
      return this.$store.dispatch('article/detail/getData', {
        lon: this.userCity.lon,
        lat: this.userCity.lat,
        id: id
      })
    }
  },
  metaInfo () {
    const a = this.article
    return {
      title: !a.id ? '加载中...' : a.title + '-' + a.cat_name + '-网站新闻-' + this.s.PROD_NAME,
      meta: [
        { name: 'keywords', content: a.keywords },
        { name: 'description', content: a.introduction },
        { name: 'mobile-agent', content: 'format=html5; url=' + this.s.mUrl() + this.$route.fullPath }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.row1 {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

}

@media (min-width: 1000px) {
  .row1, .row2 {
    flex-direction: row;
  }

  .row1-main {
    width: 650px;
  }

  .row1-right {
    width: 340px;
    margin-left: 10px;
  }
}

@media (max-width: 999px) {
  .row1, .row2 {
    flex-direction: column;
  }

  .row1-main, .row1-right{
      width: 100%;
    }

}
</style>
