<template>
    <!-- TODO: 路由变化回到顶部 -->
    <div class="pagination">
        <!-- 当前页面展示多少条数据 -->
        <div v-show="showTotal" class="total">共 {{total}} 条</div>
        <!-- 页码部分 -->
        <ul class="pagesInner">
            <!-- 上一页 -->
            <li class="page" v-if="currPage != 1 && totalPage > 1">
                <router-link :to="makeUrl(-1, true)">上一页</router-link>
            </li>
            <!-- 页码 -->
            <li class="page" :class="{actived : item === currPage}" v-for="(item, index) in displayPages" :key="index">
                <router-link :to="makeUrl(item,false)">{{item}}</router-link>
            </li>
            <!-- 下一页 -->
            <li class="page" v-if="currPage != totalPage && totalPage > 1">
                <router-link :to="currPage == totalPage ? $route.path : makeUrl(1,true)">下一页</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import { parseParams, appendParam } from '@/utils/link'
/** 分页器 源码https://www.cnblogs.com/carriezhao/p/11102518.html  已做很大的改动 */
export default {
  // middleSize 中间显示页数
  props: {
    total: { type: Number, required: true },
    perPage: { type: Number, required: true },
    middleSize: { type: Number, required: true },
    showTotal: { type: Boolean, required: false }
  },
  computed: {
    currPage: {
      get () {
        return this.getCurrPage()
      },
      set () {
        return this.getCurrPage()
      }

    },
    totalPage: {
      get () {
        return Math.ceil(parseInt(this.total) / parseInt(this.perPage))
      },
      set () {
        return Math.ceil(parseInt(this.total) / parseInt(this.perPage))
      }
    },
    // 展示页码
    displayPages () {
      // ① 只展示'上一页'、'页码'、'下一页'
      if (this.totalPage <= this.middleSize + 2) {
        let pages = []
        for (let i = 1; i <= this.totalPage; i++) {
          pages.push(i)
        }
        return pages
      } else if (this.currPage < this.middleSize) {
        let pages = [1]
        for (let i = 2; i < this.middleSize + 1; i++) {
          pages.push(i)
        }
        return pages
      } else if (this.currPage > this.totalPage - (this.middleSize - 1)) {
        // ③主要当前页码 大于或等于 总页码-(中间要展示页码的个数 - 1)就展示
        let pages = []
        for (let i = this.totalPage - this.middleSize + 1; i <= this.totalPage; i++) {
          pages.push(i)
        }
        return pages
      } else {
        let pages = []
        // 当前页码 - (中间要展示页码的个数/2向下取整) 到 当前页码 + (中间要展示页码的个数/2向下取整)即可
        const half = Math.floor(this.middleSize / 2) // 向下取整
        for (let i = this.currPage - half; i <= this.currPage + half; i++) {
          pages.push(i)
        }
        return pages
      }
    }
  },
  methods: {
    makeUrl (number, isUpOrDown) {
      if (isUpOrDown) {
        number = this.currPage + number
      }
      let url = this.$route.path
      return appendParam(url, 'page', number)
    },
    getCurrPage () {
      return parseInt(parseParams(this.$route.params.PARA_TEXT).page) || 1
    }
  }
}
</script>

<style lang="less" scoped>
.pagination {
    display: flex;
    justify-content: left;

    .total {
        font-size: 14px;
    }
    .pagesInner {
        display: inline-block;
        text-align: center;
        font-size: 0;
        li {
            display: inline-block;
            margin: 2px;
            list-style: none;
            >a {
                display: block;
                width: 42px;
                height: 36px;
                line-height: 36px;
                text-align: center;
                font-size: 12px;
                border: 1px solid #eee;
                border-radius: 5px;
                cursor: pointer;
            }
            &.actived {
                >a {
                    border-color: #2d8cf0;
                    background-color: #2d8cf0;
                    color: #fff;
                }
            }
        }
    }
}
</style>
