<template>
  <div class="cat">
    <h2 class="cat-header">
      <router-link :to="makeInfoCatUrl(cat)" :title="cat.title" class="cat-title">
        <i :class="'iconfont ' + cat.icon_class"></i> {{cat.name}}</router-link></h2>
    <ul class="w-list-h cat-subcats">
      <li v-for="(subCat, i) in cat.children" :key="i">
        <router-link :to="makeInfoCatUrl(subCat)" :title="userCity.name+subCat.title">{{subCat.name}}</router-link>
      </li>
    </ul>
    <ul class="w-list-v">
      <li v-for="(info, i) in cat.list" :key="i">
        <simple-info-row :info="info"/>
      </li>
    </ul>
  </div>
</template>

<script>
import SimpleInfoRow from '@/components/SimpleInfoRow'
import { makeInfoCatUrl, makeInfoUrl } from '@/utils/link'

export default {
  components: {
    SimpleInfoRow
  },
  props: ['cat'],
  methods: {
    makeInfoCatUrl,
    makeInfoUrl
  }
}
</script>

<style lang="less" scoped>
.cat {
  max-width: 100%;
  .cat-header {
    border-bottom: solid 1px #ddd;
    height: 40px;
    line-height: 40px;
    padding-left: 10px;
    .cat-title{
      color: #00a0e6;
    }
  }

  .cat-subcats {
    padding: 10px;
    a {
      color: #00a0e6;
    }
  }
}
</style>
