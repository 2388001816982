<template>
  <router-link :to="makeInfoUrl(info)" :title="info.region_name+' - '+info.title">
    <div class="w-row">
      <div class="w-row-left" v-if="displayTime">
        <span>{{ info.refresh_time | wTime }}</span>
      </div>
      <div class="w-row-center">
        <!-- 显示城市+标题或仅显示标题 -->
        <span v-if="!onlyTitle">
          {{ info.cityid===userCity.id? '': info.city_name+ ':' }}
        </span>
        <span>
          <i class="iconfont icon-llmenupic4yes" v-if="info.main_img"/>
          {{ info.title }}
        </span>
      </div>
      <div class="w-row-right">
        <span v-if="info.is_top">
          <i class="iconfont icon-ding" />
        </span>
      </div>
    </div>
  </router-link>
</template>

<script>
import { makeInfoUrl } from '@/utils/link'

export default {
  props: {
    info: { type: Object, required: true },
    displayTime: { type: Boolean, default: true },
    onlyTitle: { type: Boolean, default: false }
  },
  methods: {
    makeInfoUrl
  }
}
</script>

<style lang="less" scoped>

</style>
