import * as api from '@/api/info';
import * as regionApi from '@/api/region';
const DTF_INFO = { id: 0, sub_catid: 265, cat_name: '' };
const state = () => ({
    info: DTF_INFO,
    relevantInfo: [],
    nearbyCities: [],
    bigCatLatestInfo: [],
    subCats: [],
    hotCities: {}
});
// getters
const getters = {
    info: (state) => state.info,
    relevantInfo: (state) => state.relevantInfo,
    nearbyCities: (state) => state.nearbyCities,
    bigCatLatestInfo: (state) => state.bigCatLatestInfo,
    subCats: (state) => state.subCats,
    hotCities: (state) => state.hotCities
};
// actions
const actions = {
    async getInfo(context, id) {
        let info = null;
        try {
            const { data } = await api.getDetailInfo(id);
            info = data.info;
        }
        catch (error) {
            const cat = api.getRandomBigCat();
            info = {
                cityid: 0,
                parent_catid: cat.id,
                parent_name: cat.name,
                title: '加载信息失败，可能已经删除',
                content: '加载信息失败，请刷新再试。如果网络正常，但是一直失败，可能已删除。'
            };
        }
        await context.dispatch('getBigCatLatestInfo', { id, catid: info.parent_catid, cityid: info.cityid });
        await context.dispatch('getNearbyCities', { cityid: info.cityid });
        await context.dispatch('getSubCats', info.parent_catid);
        context.commit('saveInfo', info);
    },
    async getRelevantInfo(context, params) {
        const { data } = await api.getRelevantInfo(params);
        context.commit('saveRelevantInfo', data.relevantInfo);
    },
    async getNearbyCities(context, params) {
        const { data } = await regionApi.getNearbyCities(params);
        context.commit('saveNearbyCities', data.nearbyCities);
    },
    async getBigCatLatestInfo(context, params) {
        const { data } = await api.getBigCatLatestInfo(params);
        context.commit('saveBigCatLatestInfo', data.bigCatLatestInfo);
    },
    async getSubCats(context, catid) {
        const { data } = await api.getSubCats(catid);
        context.commit('saveSubCats', data.subCats);
    },
    async getHotCities(context) {
        const { data } = await regionApi.getHotCities();
        context.commit('saveHotCities', data.cities);
    }
};
// mutations
const mutations = {
    'saveInfo'(state, info) {
        state.info = info;
    },
    'saveRelevantInfo'(state, relevantInfo) {
        state.relevantInfo = relevantInfo;
    },
    'saveNearbyCities'(state, nearbyCities) {
        state.nearbyCities = nearbyCities;
    },
    'saveBigCatLatestInfo'(state, bigCatLatestInfo) {
        state.bigCatLatestInfo = bigCatLatestInfo;
    },
    'saveSubCats'(state, subCats) {
        state.subCats = subCats;
    },
    'saveHotCities'(state, hotCities) {
        state.hotCities = hotCities;
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
