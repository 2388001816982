<template>
    <div class="cats">
      <div v-for="cat in cats" :key="cat.id" class="cat w-card">
        <div class="w-list-title">
          <h2 class="cat-name">{{cat.name}}</h2>
          <router-link class='cat-more' :to="makeArticleCatUrl(cat)" :title="cat.name+'频道'">更多</router-link>
        </div>
        <ul class="w-list-v">
          <li v-for="a in cat.list" :key="a.id">
          <simple-article-row  :article="a" :displayTime="true"/>
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
import { makeArticleUrl, makeArticleCatUrl } from '@/utils/link'
import SimpleArticleRow from '@/components/SimpleArticleRow.vue'

export default {
  props: ['cats'],
  components: {
    SimpleArticleRow
  },
  methods: {
    makeArticleUrl,
    makeArticleCatUrl
  }
}
</script>

<style lang="less" scoped>
.cats {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .cat {
      flex: 0 0 350px;
    }

    .w-list-title {
      height: 30px;
      h2 {
        font-size: 16px;
      }
      a {
        font-size: 14px;
    }
  }
}

@media (max-width: 999px){
  .cats {
    flex-direction: column;
  }

  .cat {
    flex: 0 1 auto;
  }
}
</style>
