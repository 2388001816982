<template>
  <div id="main">
    <w-header />
     Store layout {{$route.params}}
    <router-view />
    <w-footer />
  </div>
</template>

<script>
import WHeader from '@/views/layout/components/WHeader.vue'
import WFooter from '@/views/layout/components/WFooter.vue'

export default {
  components: {
    WHeader,
    WFooter
  },
  metaInfo: {} // 必须保留，否则没有设置metaInfo的页面会报错
}
</script>

<style lang="less" scoped>
</style>
