import { render, staticRenderFns } from "./WFooter.vue?vue&type=template&id=e18a3d76&scoped=true"
import script from "./WFooter.vue?vue&type=script&lang=js"
export * from "./WFooter.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./WFooter.vue?vue&type=style&index=0&id=e18a3d76&prod&lang=less&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "e18a3d76",
  "59964a6c"
  
)

export default component.exports