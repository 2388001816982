<template>
  <div>
    <div class="w-input-addon">
      <input v-model="keywords" class="w-input-addon-field"/>
      <button class="w-input-addon-item" @click="seach()">搜索</button>
    </div>
    <p class="selections">
      <router-link
       v-for="(word, index) in hot_words"
       :key="index"
       :to="makeUrl(word)"
      >{{word}}</router-link>
    </p>
  </div>
</template>

<script>
import { buildUrl, makeCityUrl } from '@/utils/link.ts'

export default {
  data: () => {
    return {
      hot_words: [ '小单', '清加工', '裁片', '车工', '棉衣' ],
      keywords: ''
    }
  },
  methods: {
    buildUrl,
    seach () {
      this.$router.push(this.makeUrl(this.keywords))
      this.keywords = ''
    },
    makeUrl (keywords) {
      let url = !keywords ? '/search.html' : buildUrl('/search', { keywords })
      return makeCityUrl(url, this.userCity.dir)
    }
  }
}
</script>

<style lang="less" scoped>
.w-input-addon {
  width: 450px;
}

.w-input-addon-field {
  border-top-left-radius: 1em!important;
  border-bottom-left-radius: 1em!important;
}

.w-input-addon-item {
  border-top-right-radius: 1em!important;
  border-bottom-right-radius: 1em!important;
  border-color: #ccc;
  color: #eee;
  background-color: #32b6fa;
  cursor: pointer;
}

.w-input-addon-item:hover {
  color: #ddd;
}

.selections {
  margin: 0;
  padding: 5px;
  position:absolute;
}

.selections a {
  color: #eee;
  margin-right: 5px;
}

.selections a:hover {
  color: #888;
}

</style>
