import { render, staticRenderFns } from "./ShopIndexPage.vue?vue&type=template&id=1032b970&scoped=true"
import script from "./ShopIndexPage.vue?vue&type=script&lang=js"
export * from "./ShopIndexPage.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "1032b970",
  "3b51ac98"
  
)

export default component.exports