<template>
  <div>
    <div class="row1">
      <div class="row1-left">
          <index-cats-menu :cats="catInfoList" />
        </div>
        <div class="row1-middle">
          <focus :list="foci || []" oStyle="focus600x200" height="200px" class="row1-middle-focus" />
          <div class="row1-middle-text">
            <index-hot-info-list :list="hotInfoList" class="row1-middle-text-info" />
            <index-article-and-announce
              :articles="articles"
              :announces="announces"
              class="row1-middle-text-announce"
            />
          </div>
        </div>
        <div class="row1-right">
          <index-login-panel />
        </div>
      </div>
    <div class="row2">
      <div v-for="(cat, i) in catInfoList" :key="i" class="row2-cat">
          <index-cat-info-list :cat="cat" v-if="cat.list && cat.list.length >0" />
        </div>
      </div>
    <div class="row3">
      <index-hot-cities :cities="hotCities" />
      </div>
    <div class="row4">
      <friend-links :links="flinks" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FriendLinks from '@/components/FriendLinks.vue'
import IndexCatInfoList from '@/views/main/components/IndexCatInfoList.vue'
import IndexCatsMenu from '@/views/main/components/IndexCatsMenu.vue'
import Focus from '@/components/Focus.vue'
import IndexHotCities from '@/views/main/components/IndexHotCities.vue'
import IndexArticleAndAnnounce from '@/views/main/components/IndexArticleAndAnnounce.vue'
import IndexHotInfoList from '@/views/main/components/IndexHotInfoList.vue'
import IndexLoginPanel from '@/views/main/components/IndexLoginPanel.vue'
import { getIndexData, getIndexCatInfoList } from '@/api/info'
import { isEmpty } from '@/utils/object'
import { Link } from 'element-ui'
import { parseParamsFromUrl, parseCityDir } from '../../utils/link'

export default {
  components: {
    FriendLinks,
    IndexCatInfoList,
    IndexCatsMenu,
    Focus,
    IndexHotCities,
    IndexArticleAndAnnounce,
    IndexHotInfoList,
    IndexLoginPanel
  },
  data: function () {
    return { data: {} }
  },
  computed: {
    ...mapGetters({
      foci: 'main/index/foci',
      hotInfoList: 'main/index/hot_info_list',
      catInfoList: 'main/index/cat_info_list',
      hotCities: 'main/index/hot_cities',
      articles: 'main/index/articles',
      announces: 'main/index/announces',
      flinks: 'main/index/flinks'
    })
  },
  serverPrefetch () {
    return this.fetchData(this.$route.params.city_dir)
  },
  mounted () {
    this.fetchData(this.$route.params.city_dir)
  },
  beforeRouteUpdate (to, from, next) {
    const dir = parseCityDir(to.path)
    this.fetchData(dir)
    next()
  },
  methods: {
    fetchData (dir) {
      return Promise.all([
        this.$store.dispatch('main/index/getIndexData', dir),
        this.$store.dispatch('main/index/getCatInfoList', dir)
      ])
    }
  },
  metaInfo () {
    const c = this.userCity
    return {
      title: c.title ? c.title : (c.name || '') + '服装订单外发加工_招聘求职_批发采购_' + this.s.PROD_NAME,
      meta: [
        { name: 'keywords', content: c.keywords ? c.keywords : (c.name || '') + '服装八场，服装加工，服装外发订单，承接外发订单，制衣厂找附近货源，稳定客户，长期合作，服装招聘求职，批发采购，面辅料，服装设备转让，厂房租让，八厂， 8场，8厂' },
        { name: 'description', content: c.description ? c.description : (c.name || '') + '领先的服装加工平台，聚集了众多优质中小服装加工厂，找外发加工订单货源，附近制衣厂，服装从业者，长期客户货源，招聘求职，批发采购，设备，厂房信息全覆盖。原名服装微加工。' },
        { name: 'mobile-agent', content: 'format=html5; url=' + this.s.mUrl() + this.$route.fullPath }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.row1 {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  height: 508px;
  .row1-left {
    flex: 0 0 190px;
  }
  .row1-middle {
    flex: 0 0 600px;
    .row1-middle-focus {
      text-align: center;
    }
    .row1-middle-text {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      height: 300px;
      .row1-middle-text-info,
      .row1-middle-text-announce {
        width: 295px;
        flex: 0 0 295px;
      }
    }
  }
  .row1-right {
    flex: 0 0 190px;
  }
}

.row2 {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  .row2-cat {
    flex: 0 1 495px;
    border: solid 1px #ddd;
    border-radius: 4px;
    background-color: #fff;
    margin-top: 10px;
    min-height: 100px;
    max-width: 495px;
  }
}

/* 热门城市 */
.row3 {
  height: 100px;
  margin-top: 40px;
}

/* 友情链接 */
.row4 {
  margin-top: 20px;
}

@media (min-width: 1000px) {
  .row1,
  .row2 {
    flex-direction: row;
  }
}

@media (max-width: 999px) {
  .row1,
  .row2 {
    flex-direction: column;
  }

  .row1 {
    height: auto;
    .row1-middle {
      flex: 0 1 auto;
      .row1-middle-text {
        flex-flow: column;
        align-items: center;
        .row1-middle-text-info,
        .row1-middle-text-announce {
          width: 100%;
          flex: 1 1 auto;
        }
        .row1-middle-text-announce {
          margin-top: 10px;
          height: 360px;
        }
      }
    }
    .row1-left {
      display: none;
    }
    .row1-right {
      display: none;
    }
  }
  .row2 {
    .row2-cat {
      flex: 0 1 auto;
      max-width: 100%;
      width: 100%;
    }
  }
}
</style>
