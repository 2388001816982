import axios from 'axios';
import axiosRetry from 'axios-retry';
import settings from '@/core/settings';
import { getToken, setToken } from './token';
import { getCid } from './cid';
const appendHeaders = (config) => {
    // Add X-Access-Token header to every request, you can add other custom headers here
    const token = getToken();
    if (token) {
        config.headers.Authorization = token;
    }
    config.headers.Cid = getCid();
};
const setTokenIfExists = (response) => {
    // 判断一下响应中是否有 token，如果有就直接使用此 token 替换掉本地的 token。你可以根据你的业务需求自己编写更新 token 的逻辑
    const token = response.headers.authorization;
    if (token) {
        // 如果 header 中存在 token，替换本地的 token
        setToken(token);
    }
};
const handleLaravelMethod = (config) => {
    // LARAVEL put, patch, delete 请求必须转换为post请求
    // https://www.5balloons.info/how-to-use-putpatch-and-delete-method-in-laravel-5-5/
    const methods = ['put', 'PUT', 'patch', 'PATCH', 'delete', 'DELETE'];
    const m = config.method;
    if (methods.indexOf(m) >= 0) {
        config.method = 'post';
        if (config.data) {
            config.data._method = m;
        }
        if (config.params) {
            config.data._method = m;
        }
    }
};
const handleFailure = (res) => {
    // code 可能值如下，一般由调用的地方处理，不予通用处理。
    // code == 20000: success
    // code == 50001: 非法令牌
    // // code == 50002: already login in other place
    // // code == 50003: access token expired
    // // code == 50004: 非法用户（用户不存在）
    // code == 50005: 用户名或者密码错误
    // code == 50006: 角色未授权
    // code == 70001: 系统报错
    const err = res || '错误';
    return Promise.reject(JSON.stringify(err));
};
const service = axios.create({
    baseURL: settings.apiBaseUrl(), // url = base url + request url
    timeout: settings.requestTimeout()
    // withCredentials: true // send cookies when cross-domain requests
});
// Request interceptors
service.interceptors.request.use((config) => {
    config.withCredentials = true;
    appendHeaders(config);
    handleLaravelMethod(config);
    return config;
}, (error) => {
    Promise.reject(error);
});
// Response interceptors
service.interceptors.response.use((response) => {
    setTokenIfExists(response);
    const res = response.data;
    if (res.code !== 20000) {
        return handleFailure(res);
    }
    else {
        return response.data;
    }
}, (error) => {
    return Promise.reject(error);
});
// 使用默认的重试策略：即
// 1. 网络错误:
//    没有response，客户端报错
// 2. 服务端报错:
//    response.status为5xx，并且method为['get', 'head', 'options','put', 'delete']（调用多次，结果不变）
axiosRetry(service, { retries: 3 });
export default service;
