<template>
    <div class="w-cat-page-filter">
        <h2 class="w-cat-page-filter-name">分类：</h2>
        <ul class="w-cat-page-filter-content">
            <li v-for="cat in sel.cats" :key="cat.id">
                <router-link
                 :to="makeUrl(cat.id)"
                 :title="regionName+cat.title"
                 :class="cat.id===sel.catid ? 'active' : ''">{{cat.name}}</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import { isPositiveInteger } from '@/utils/validate'
import { removeParam, appendParam } from '@/utils/link'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      settings: 'main/search/settings', // 所有可选值,
      sel: 'main/search/selection',
      regionName: 'main/search/regionName'
    })
  },
  methods: {
    makeUrl (id) {
      let url = removeParam(this.$route.path, 'page,keywords')
      return appendParam(url, 'catid', id)
    }
  }
}
</script>

<style lang="less" scoped>
.w-cat-page-filter {
    margin-bottom: 10px
}

.active {
    color: #57a2f9;
}
</style>
