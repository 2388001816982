import request from '@/utils/request';
import { getCid } from '@/utils/cid';
const BIG_CAT_WAIFA = 246;
const BIG_CAT_ZHAOPIN = 367;
const BIG_CAT_CHENGJIE = 264;
const BIG_CAT_QIUZHI = 299;
const BIG_CAT_PIFA = 327;
const BIG_CAT_CHANFANG = 323;
const BIG_CAT_MIANFULIAO = 338;
const BIG_CAT_SHEBEI = 309;
const BIG_CAT_LINSHIGONG = 405;
const BIG_CAT_GUANGGAO = 387;
const BIG_CAT_XIANGQIN = 416;
const BIG_CATS = [
    { id: BIG_CAT_WAIFA, name: '外发加工' },
    { id: BIG_CAT_ZHAOPIN, name: '招聘' },
    { id: BIG_CAT_CHENGJIE, name: '承接加工' },
    { id: BIG_CAT_QIUZHI, name: '求职' },
    { id: BIG_CAT_PIFA, name: '批发' },
    { id: BIG_CAT_CHANFANG, name: '厂房住房' },
    { id: BIG_CAT_MIANFULIAO, name: '面辅料' },
    { id: BIG_CAT_SHEBEI, name: '设备' },
    { id: BIG_CAT_LINSHIGONG, name: '临时工' },
    { id: BIG_CAT_GUANGGAO, name: '广告' }
];
export const getRandomBigCat = () => {
    const r = Math.round(Math.random() * 100);
    return BIG_CATS[r % BIG_CATS.length];
};
export const TOP_TYPE_MAP = new Map([
    [1, '全国首页置顶'],
    [2, '全国大类置顶'],
    [3, '全国大类置顶'],
    [4, '城市首页置顶'],
    [5, '城市大类置顶'],
    [6, '城市小类置顶']
]);
// 0: 等待置顶, 1: 置顶中, 2: 置顶结束 表中不存在这些字段，仅查询展示用
export const TOP_TAB_WAITING = 0;
export const TOP_TAB_ON_TOP = 1;
export const TOP_TAB_END = 2;
export const getTopsTab = (tops) => {
    var currentTime = Math.trunc(new Date().getTime() / 1000);
    tops.forEach((top) => {
        if (currentTime < top.begin_time && top.enabled === 1) {
            top.tab = TOP_TAB_WAITING;
        }
        else if (currentTime >= top.begin_time && currentTime <= top.end_time && top.enabled === 1) {
            top.tab = TOP_TAB_ON_TOP;
        }
        else {
            top.tab = TOP_TAB_END;
        }
    });
    return tops;
};
export const DEFAULT_CAT = {
    name: '',
    title: '服装信息',
    keywords: '外发加工，承接加工，招聘，求职，批发采购，面辅料，临时工，厂房',
    description: '服装外发加工，承接加工，求职，招聘，批发采购，面辅料，临时工，厂房设备等...'
};
export const getIndexData = (city_dir) => request({
    url: `page/w_index`,
    data: { city_dir },
    method: 'post'
});
export const getIndexCatInfoList = (city_dir) => request({
    url: `page/w_index_cat_info_list`,
    data: { city_dir },
    method: 'post'
});
export const getSubCats = (catid) => request({
    url: `info_cat/sub_cats/${catid}`,
    method: 'get'
});
export const getSimpleInfo = (id) => request({
    url: `info/simple/${id}`,
    method: 'get'
});
export const getDetailInfo = (id) => request({
    url: `info/detail/${id}`,
    method: 'get'
});
export const getRelevantInfo = (data) => request({
    url: `info/relevant`,
    method: 'post',
    data
});
export const getBigCatLatestInfo = (data) => request({
    url: `info/big_cat_latest_list`,
    method: 'post',
    data
});
export const recordHit = (id) => request({
    url: 'info/hit',
    method: 'post',
    data: {
        id,
        cid: getCid(),
        url: decodeURIComponent(window.location.href)
    }
});
// 记录展示次数
export const recordDisplay = (ids, otherIds = []) => {
    const all = otherIds.length <= 0 ? ids : [...[], ...ids, ...otherIds];
    request({
        url: 'info/display',
        method: 'post',
        data: { ids: all.map((info) => { return info.id; }) }
    });
};
// 获取用户信息浏览、拨打、收藏的记录
export const getRecords = (params) => request({
    url: `info/records`,
    method: 'post',
    params
});
export const doFavorite = (data) => request({
    url: `info/favorite`,
    method: 'post',
    data
});
export const getCats = () => request({
    url: `/info/cats`,
    method: 'get'
});
export const getCat = (id) => request({
    url: `/info_cat/${id}`,
    method: 'get'
});
export const getPosts = (data) => request({
    url: `/info/posts`,
    method: 'post',
    data: data
});
export const refresh = (data) => request({
    url: `/info/refresh`,
    method: 'post',
    data
});
export const getRefreshStatus = (data) => request({
    url: `/info/refresh_status`,
    method: 'post',
    data: data
});
export const cancel = (data) => request({
    url: `/info/cancel`,
    method: 'post',
    data
});
export const searchSettings = (data) => request({
    url: '/info/w_search_settings',
    method: 'post',
    data
});
export const search = (paras) => request({
    url: '/info/search',
    method: 'post',
    data: paras
});
export const userPosts = (userid) => request({
    url: '/info/user_posts',
    method: 'post',
    data: { userid }
});
export const qrCode = (id) => request({
    url: `/info/qr_code/${id}`,
    method: 'get'
});
