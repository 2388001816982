<template>
  <div>
    <h2 class="u-info">
      <el-row :gutter="10">
        <el-col :span="3">
          <w-img
            :opath="user.logo_path"
            ostyle="s89"
            :altText="user.nickname"
            class="u-logo"
          />
        </el-col>
        <el-col :span="6">
          <div class="u-segment">
            {{user.nickname}}
            <el-tag
              v-if="user.certified>0"
              type="success"
              effect="dark">
              已实名
            </el-tag>
          </div>
        </el-col>
        <el-col :span="6">
          <div
            v-show="user.credit>0"
            class="u-segment"
          >
            <label>信用值:</label><span>{{user.credit}}</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="u-segment">
            <label>注册于:</label>
            <span>{{user.created_at | wTime('yyyy年MM月dd日') }}</span>
          </div>
        </el-col>
      </el-row>
    </h2>
    <div class="info-list">
      <h3>{{user.nickname}} 发布的信息</h3>
      <ul class="w-list-v">
          <li v-for="info in list" :key="info.id" class="info-list-row">
            <info-row :info="info"/>
          </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InfoRow from '@/components/InfoRow.vue'

export default {
  components: {
    InfoRow
  },
  computed: {
    ...mapGetters({
      user: 'main/space/user',
      list: 'main/space/list'
    })
  },
  serverPrefetch () {
    return this.fetchData()
  },
  mounted: function () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      const userid = this.$route.params.userid
      return Promise.all([
        this.$store.dispatch('main/space/getUserInfo', userid),
        this.$store.dispatch('main/space/getUserPosts', userid)
      ])
    }
  }
}
</script>

<style lang="less" scoped>
.u-info {
  margin-top: 10px;
  padding: 15px;
  font-weight: normal;
  .u-logo {
    border-radius:50% 50% 50% 50%;
  }
  .u-segment {
    padding-top: 30px;
  }
}
.info-list {
  margin-top: 10px;
  padding: 15px;
  h3 {
    padding-bottom: 5px;
    border-bottom: solid 1px #ccc;
    font-weight: normal;
  }
}
</style>
