<template>
    <div class="w-card">
      <div class="latest-articles-row-first">
          <h2><router-link :to="makeArticleUrl(latestArticles[0])">{{latestArticles[0].title}}</router-link></h2>
          <span>{{latestArticles[0].introduction}}</span>
          <span><router-link :to="makeArticleUrl(latestArticles[0])">查看全文 >></router-link></span>
      </div>
      <ul class="w-list-v">
        <li v-for="a in latestArticles.slice(1,14)" :key="a.id">
          <simple-article-row  :article="a" :displayTime="true"/>
        </li>
      </ul>
    </div>
</template>

<script>
import { makeArticleUrl } from '@/utils/link'
import SimpleArticleRow from '@/components/SimpleArticleRow.vue'

export default {
  props: { latestArticles: { type: Array } },
  components: {
    SimpleArticleRow
  },
  methods: {
    makeArticleUrl
  }
}
</script>

<style lang="less" scoped>
.latest-articles-row-first {
  flex-grow: 5;
  padding: 20px 10px 10px 10px;
  height: 100px;
  h2 {
    text-align: center;
    margin-bottom: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  span {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
      a {
        font-size: 12px;
      }
  }
  a {
    color: #45a3fa;
  }
}
</style>
