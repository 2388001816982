<template>
    <div class="read-rank w-card">
      <div class="w-list-title">
        <h2>热门阅读排行榜</h2>
      </div>
      <ul class="w-list-v">
        <li v-for="(a, i) in latestArticles" :key="i">
          <simple-article-row  :article="a" :displayTime="false" :rankNumber="i+1"/>
        </li>
      </ul>
    </div>
</template>

<script>
import { makeArticleUrl } from '@/utils/link'
import SimpleArticleRow from '@/components/SimpleArticleRow.vue'

export default {
  props: ['latestArticles'],
  components: {
    SimpleArticleRow
  },
  methods: {
    makeArticleUrl
  }
}
</script>

<style lang="less" scoped>
</style>
