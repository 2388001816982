<template>
  <router-link :to="makeArticleUrl(article)" :title="article.introduction">
    <div class="w-row">
      <div class="w-row-left" v-if="rankNumber !==0">
        <span>{{rankNumber}}</span>
      </div>
      <div class="w-row-center">
        <span>
          {{ article.title }}
        </span>
      </div>
      <div class="w-row-right" v-if="displayTime">
        <span>{{ article.created_at | wTime('MM月dd日') }}</span>
      </div>
    </div>
  </router-link>
</template>

<script>
import { makeArticleUrl } from '@/utils/link'

export default {
  props: {
    article: { type: Object, required: false },
    displayTime: { type: Boolean, default: true },
    rankNumber: { type: Number, default: 0 }
  },
  methods: {
    makeArticleUrl
  }
}
</script>

<style lang="less" scoped>
 .w-row-right {
    flex: 0 0 90px;
 }

 .w-row-left {
   flex: 0 0 25px;
 }
</style>
