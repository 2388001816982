import { render, staticRenderFns } from "./FaqPage.vue?vue&type=template&id=97728c76&scoped=true"
import script from "./FaqPage.vue?vue&type=script&lang=js"
export * from "./FaqPage.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./FaqPage.vue?vue&type=style&index=0&id=97728c76&prod&lang=less&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "97728c76",
  "21c77991"
  
)

export default component.exports