import { render, staticRenderFns } from "./InfoRow.vue?vue&type=template&id=478aceca&scoped=true"
import script from "./InfoRow.vue?vue&type=script&lang=js"
export * from "./InfoRow.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./InfoRow.vue?vue&type=style&index=0&id=478aceca&prod&lang=less&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "478aceca",
  "ca7acc14"
  
)

export default component.exports