<template>
  <div class="w-card city-list">
    <h2>{{info.city_name}}周边</h2>
    <ul>
      <li v-for="city in nearbyCities" :key="city.id">
        <router-link
         :to="makeCityUrl(buildUrl('/category', {catid: info.parent_catid}), city.dir)"
         :title="city.name+info.parent_name+'信息'"
        >
        {{city.name + info.parent_name}}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { buildUrl, makeCityUrl } from '@/utils/link'

export default {
  props: ['info', 'catid', 'cities'],
  computed: {
    nearbyCities () {
      let cities = this.cities
      for (var i = 0; i < cities.length; i++) {
        if (this.info.cityid === cities[i].id) {
          cities.splice(i, 1)
          break
        }
      }
      return cities
    }
  },
  methods: {
    buildUrl,
    makeCityUrl
  }
}
</script>

<style lang="less" scoped>
.city-list {
  h2 {
    font-size: 14px;
    font-weight: 700;
    padding: 15px 0 0 15px;
    color: #333;
  }
  font-size: 12px;
  ul {
    padding-inline-start: 20px;
  }
  li {
    float: left;
    overflow: hidden;
    white-space: nowrap;
    width: 100px;
    padding: 5px 0;
  }
}
</style>
