import Vue from 'vue';
/**
 * 注: 仅限浏览器客户端环境判断
 */
export const isServer = Vue.prototype.$isServer;
export const isWindows = !isServer && new RegExp('windows|win32', 'i').test(navigator.userAgent);
export const isWindowsPhone = !isServer && new RegExp('IEMobile', 'i').test(navigator.userAgent);
export const isAndroid = !isServer && new RegExp('Android', 'i').test(navigator.userAgent);
// iOs.NEMO 是新app自带，iOs过短，所以 iOs.NEMO匹配
export const isIos = !isServer && new RegExp('iPhone|iPad|iPod|iOs\\.NEMO', 'i').test(navigator.userAgent);
// QQ浏览器包含 MQQBrowser 必须排除，所以用 QQ/ 匹配
export const isQQ = !isServer && new RegExp('QQ\\/', 'i').test(navigator.userAgent) && !new RegExp('NEMO', 'i').test(navigator.userAgent);
export const isWechat = !isServer && new RegExp('MicroMessenger', 'i').test(navigator.userAgent) && !new RegExp('NEMO', 'i').test(navigator.userAgent);
export const isAlipay = !isServer && new RegExp('alipay', 'i').test(navigator.userAgent);
export const isMobile = isAndroid || isIos || isWindowsPhone;
// 自版本2.1.9起,app端浏览器包含NEMO+版本号.
export const isApp = !isServer && new RegExp('NEMO', 'i').test(navigator.userAgent);
export const isBot = () => {
    if (isServer)
        return false;
    var botPattern = '(googlebot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)';
    var re = new RegExp(botPattern, 'i');
    var userAgent = navigator.userAgent;
    return re.test(userAgent);
};
export const appType = () => {
    if (isAndroid) {
        return 'android';
    }
    else if (isIos) {
        return 'ios';
    }
    else if (isWindows) {
        return 'windows';
    }
    else if (isWindowsPhone) {
        return 'windows_phone';
    }
    else {
        return 'unknown';
    }
};
export const getBrowserOrApp = () => {
    return isQQ || isWechat || isAlipay ? 'browser' : 'app';
};
