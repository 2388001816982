import { render, staticRenderFns } from "./SimpleInfoRow.vue?vue&type=template&id=13e8c884&scoped=true"
import script from "./SimpleInfoRow.vue?vue&type=script&lang=js"
export * from "./SimpleInfoRow.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "13e8c884",
  "32c95138"
  
)

export default component.exports