<template>
    <div class="w-card latest-info">
      <div class="w-list-title">
        <h2>最新发布的信息</h2>
      </div>
      <ul class="w-list-v">
        <li v-for="(info, i) in latestInfo" :key="i">
          <simple-info-row :info="info" :onlyTitle="true"/>
        </li>
      </ul>
    </div>
</template>

<script>
import { makeInfoUrl } from '@/utils/link'
import SimpleInfoRow from '@/components/SimpleInfoRow'

export default {
  props: ['latestInfo'],
  components: {
    SimpleInfoRow
  },
  methods: {
    makeInfoUrl
  }
}
</script>

<style lang="less" scoped>
.latest-info {
  width: 100%;
}
.w-list-v li {
  padding-top: 4px;
}
</style>
