import { render, staticRenderFns } from "./FriendLinks.vue?vue&type=template&id=a59bd538&scoped=true"
import script from "./FriendLinks.vue?vue&type=script&lang=js"
export * from "./FriendLinks.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./FriendLinks.vue?vue&type=style&index=0&id=a59bd538&prod&lang=less&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "a59bd538",
  "109b7a36"
  
)

export default component.exports