import { render, staticRenderFns } from "./ShopAlbumPage.vue?vue&type=template&id=4f352cce&scoped=true"
import script from "./ShopAlbumPage.vue?vue&type=script&lang=js"
export * from "./ShopAlbumPage.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "4f352cce",
  "1ab89251"
  
)

export default component.exports