import VueAnalytics from 'vue-analytics';
import settings from '@/core/settings';
import Vue from 'vue';
import { appType, isQQ, isServer, isWechat, isBot } from './clientEnv';
import { getCid } from './cid';
// 谷歌统计
export const configGA = (router) => {
    if (isServer)
        return;
    Vue.use(VueAnalytics, {
        id: 'UA-78610018-3',
        router,
        disabled: settings.isDev
    });
};
// 绑定用户userid与谷歌unique id，便于跟踪
// https://support.google.com/analytics/answer/3123662?hl=en&ref_topic=3123660
export const bindCid = (cid) => {
    if (isServer)
        return;
    Vue.$ga.set('cid', cid);
};
const sendError = (desc, fatal = false) => {
    if (isServer)
        return;
    desc += '\ncid:' + getCid();
    desc += '\npath:' + location.href;
    desc += '\nappType:' + appType();
    desc += '\nisWechat:' + isWechat;
    desc += '\nQQ:' + isQQ;
    desc += '\nisBot:' + isBot();
    desc += '\nua:' + navigator.userAgent;
    if (settings.isDev())
        return; // 开发时不发送错误
    Vue.$ga.query('send', 'exception', {
        exDescription: desc,
        exFatal: fatal
    });
};
export const reportError = (tag, err) => {
    let desc = tag;
    if (err.response) {
        desc += '\nresponse\n  data:' + JSON.stringify(err.response.data);
        desc += '\n  status:' + JSON.stringify(err.response.status);
        desc += '\n  headers:' + JSON.stringify(err.response.headers);
    }
    if (err.request) {
        desc += '\nrequest:' + JSON.stringify(err.request);
    }
    if (err.config)
        desc += '\nconfig:' + JSON.stringify(err.config);
    if (err.stack)
        desc += '\nstack:' + err.stack;
    if (err.message)
        desc += '\nmessage:' + err.message;
    if (err.code)
        desc += '\ncode:' + err.code;
    sendError(desc);
};
export const logGlobalError = () => {
    if (isServer)
        return;
    Vue.config.errorHandler = (err, vm, info) => {
        // err: error trace
        // vm: component in which error occured
        // info: Vue specific error information such as lifecycle hooks, events etc.
        // https://developers.google.com/analytics/devguides/collection/analyticsjs/exceptions
        // https://github.com/MatteoGabriele/vue-analytics/blob/d0fe78c4dc0e3a310ae440cae9096104eb396cc7/src/lib/exception.js
        const options = vm && vm.$options ? vm.$options : {};
        let desc = 'vue error:' + err.stack;
        desc += ' \nfile:' + options['__file'];
        desc += ' \ninfo:' + info;
        sendError(desc);
    };
    // Vue之外的错误
    window.onerror = function (msg, source, line, col, error) {
        let desc = 'window error:' + msg;
        desc += ' \nsource:' + source;
        desc += ' \nline:' + line;
        desc += ' \ncol:' + col;
        desc += ' \nstack:' + (error ? error.stack : '');
        sendError(desc);
        // When the function returns true, this prevents the firing of the default event handler.
        return true;
    };
};
