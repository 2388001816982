<template>
  <div>
    <breadcrumb :items="breadcrumbItems" :city="{id: userCity.id, name: userCity.name}"/>
    <div class="info-page">
      <div class="info-page-main">
        <info-detail :info="info" />
        <div class="w-card tuijian-info-title"><h2>推荐信息</h2></div>
        <search-info-list
         :list="bigCatLatestInfo"
         :total="0"
         :isPagination="false"
         class="w-card"
        />
      </div>
      <div class="info-page-right">
        <info-of-interest :list="relevantInfo" :info="info" />
        <info-cat-of-interest :cats="subCats"/>
        <nearby-cities :info="info" :catid="info.sub_catid" :cities="nearbyCities"/>
        <info-cat-of-hot-cities :info="info" :cities="hotCities"/>
      </div>
    </div>
  </div>
</template>

<script>
import InfoDetail from '@/views/main/components/InfoDetail.vue'
import SearchInfoList from '@/views/main/components/SearchInfoList.vue'
import InfoOfInterest from '@/views/main/components/InfoOFInterest.vue'
import InfoCatOfInterest from '@/views/main/components/InfoCatOfInterest.vue'
import InfoCatOfHotCities from '@/views/main/components/InfoCatOfHotCities.vue'
import NearbyCities from '@/views/main/components/NearbyCities.vue'
import Breadcrumb from '@/components/Breadcrumb'
import * as api from '@/api/info'
import * as regionApi from '@/api/region'
import { parseParams, buildUrl } from '@/utils/link'
import { isEmpty } from '@/utils/object'
import { mapGetters } from 'vuex'

export default {
  components: {
    InfoDetail,
    SearchInfoList,
    InfoOfInterest,
    InfoCatOfInterest,
    InfoCatOfHotCities,
    NearbyCities,
    Breadcrumb
  },
  computed: {
    ...mapGetters({
      info: 'main/info/info',
      relevantInfo: 'main/info/relevantInfo',
      nearbyCities: 'main/info/nearbyCities',
      bigCatLatestInfo: 'main/info/bigCatLatestInfo',
      subCats: 'main/info/subCats',
      hotCities: 'main/info/hotCities'
    }),
    breadcrumbItems () {
      const i = this.info
      if (!i.id) return [{ name: '', url: '/' }]
      return [
        { name: i.city_name + i.parent_name, url: buildUrl('category', { catid: i.parent_catid, cityid: i.cityid }) },
        {
          name: i.city_name + i.area_name + i.street_name + i.cat_name,
          url: buildUrl('category', {
            catid: i.sub_catid,
            cityid: this.userCity.id > 0 ? this.userCity.id : null,
            areaid: i.areaid > 0 ? i.areaid : null,
            streetid: i.streetid > 0 ? i.streetid : null
          })
        }
      ]
    }
  },
  watch: {
    '$route' (to, from) {
      this.fetchData()
    }
  },
  serverPrefetch () {
    return this.fetchData()
  },
  mounted: function () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      const id = this.$route.params.id
      return Promise.all([
        this.$store.dispatch('main/info/getInfo', id),
        this.$store.dispatch('main/info/getRelevantInfo', { id, rows: 10 }),
        this.$store.dispatch('main/info/getHotCities')
      ])
    }
  },
  metaInfo () {
    const i = this.info
    if (!i) return
    return {
      title: !i.id ? '加载中...' : i.title + (i.main_img === '' ? '' : '【图】') + '-' + i.region_name + '-' +
      i.parent_name + '-' + i.cat_name + '-' + this.s.PROD_NAME,
      meta: [
        { name: 'keywords', content: i.title + '-' + i.region_name + '-' + i.contact_who },
        { name: 'description', content: i.content + '-' + i.parent_name + '-' + i.cat_name },
        { name: 'mobile-agent', content: 'format=html5; url=' + this.s.mUrl() + this.$route.fullPath }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.info-page {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.info-page-main {
  width: 740px;
}

.info-page-right {
  width: 250px;
  margin-left: 10px;
}

.tuijian-info-title {
  margin-bottom: 0px;
  h2 {
    font-size: 14px;
    font-weight: 700;
    padding: 20px;
    color: #333;
  }

}

@media (max-width: 999px) {
  .info-page-main, .info-page-right{
      width: 100%;
      flex-direction: column;
    }
}
</style>
