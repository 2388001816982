<template>
  <div class="hc-container">
      <h3>热门城市</h3>
      <ul class="w-list-h">
        <li v-for="(c,i) in cities" :key="i">
          <router-link
            :to="makeCityUrl($route.path, c.dir)"
            :title="s.PROD_NAME+c.name+'分站'"
            @click.native="setCity(c)"
          >{{c.name}}</router-link>
        </li>
      </ul>
  </div>
</template>

<script>
import { makeCityUrl } from '@/utils/link'

export default {
  props: ['cities'],
  methods: {
    makeCityUrl,
    setCity (c) {
      // TODO: 目前userCity直接设置，待添加用户功能后参考m3的城市设置
      this.$store.dispatch('global/setCity', c)
    }
  }
}
</script>

<style lang="less" scoped>
.hc-container {
  padding-bottom: 10px;
    h3 {
      margin-bottom: 10px;
    }

    li {
      float: left;
      margin: 0px 10px 6px 0;
    }
}
</style>
