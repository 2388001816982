<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
}
</script>

<style lang="less" scoped>
@bkg: #fff;
@margin: 0px;

.main-app {
  background-color: @bkg;
  margin: @margin;
}

</style>
