import { strToNumberOrStr } from './valueUtil';
import { isEmpty } from './object';
export const makeInfoUrl = (info) => {
    return `/z${info.city_dir}/information-id-${info.id}.html`;
};
export const makeInfoCatUrl = (cat) => {
    return `category-catid-${cat.id}.html`;
};
export const makeArticleUrl = (article) => {
    return `/news-id-${article.id}.html`;
};
export const makeArticleCatUrl = (cat) => {
    return `/news-catid-${cat.id}.html`;
};
export const makeFaqUrl = (faq) => {
    return `/faq-id-${faq.id}.html`;
};
export const makeAnnounceUrl = (a) => {
    return `/announce-id-${a.id}.html`;
};
export const makeCityIndexUrl = (c) => {
    return '/z' + c.dir + '/';
};
export const CITY_GLOBAL = {
    id: 0,
    name: '全国',
    lon: 0,
    lat: 0,
    dir: '',
    title: '服装八场_服装订单外发加工_服装招聘求职好帮手',
    keywords: '服装加工厂，承接服装外发订单，加工厂找附近货源，稳定客户，裁片，长期合作，服装招聘求职，批发采购，面辅料，服装设备转让，厂房租让',
    desc: '服装加工厂，承接服装外发订单，加工厂找附近货源，稳定客户，裁片，长期合作，服装招聘求职，批发采购，面辅料，服装设备转让，厂房租让'
};
// 改变或者添加url中的city目录名 例子： /zbj/xx.html => /zgz/xx.html
export const makeCityUrl = (url, dir) => {
    const pattern = /(\/z)[^/]*(\/)/;
    if (pattern.test(url)) {
        return url.replace(pattern, '/z' + dir + '/');
    }
    if (dir) {
        return '/z' + dir + url;
    }
    return url;
};
export const parseCityDir = (url) => {
    const res = /\/z([a-z|A-Z]+)/.exec(url);
    return res ? res[1] : '';
};
/** 解析Url得到参数 例子：catid-274-cityid-5-areaid-86
返回结果object：{catid: 274, cityid: 5, areaid: 86} */
export const parseParams = (paraText) => {
    if (!paraText) {
        return {};
    }
    const arr = paraText.split('-');
    if (arr.length % 2 !== 0) {
        throw new Error('url解析不匹配:' + paraText);
    }
    const params = {};
    for (var i = 0; i < arr.length; i += 2) {
        const v = decodeURIComponent(arr[i + 1]);
        params[arr[i]] = strToNumberOrStr(v);
    }
    return params;
};
export const parseParamsFromUrl = (url) => {
    if (!url) {
        return {};
    }
    const withoutDot = url.split('.')[0]; // 删除.html
    const arr = withoutDot.split('-');
    if (arr.length % 2 !== 1) {
        throw new Error('url解析不匹配:' + url);
    }
    const params = {};
    for (var i = 1; i < arr.length && arr.length >= 3; i += 2) {
        const v = decodeURIComponent(arr[i + 1]);
        params[arr[i]] = strToNumberOrStr(v);
    }
    return params;
};
export const parsePrefix = (url) => {
    if (!url) {
        return '';
    }
    if (url.indexOf('-') !== -1) {
        return url.split('-')[0];
    }
    if (url.indexOf('.html') !== -1) {
        return url.split('.html')[0];
    }
    return url;
};
// 改变或者添加url中的参数值，仅对参数值为数字的值有用
export const appendParam = (url, key, value) => {
    const params = parseParamsFromUrl(url);
    params[key] = value;
    const prefix = parsePrefix(url);
    return buildUrl(prefix, params);
};
// 删除url中的参数，字符串以英文','隔开
export const removeParam = (url, keys) => {
    const params = parseParamsFromUrl(url);
    keys.split(',').forEach(key => {
        delete params[key];
    });
    const prefix = parsePrefix(url);
    return buildUrl(prefix, params);
};
// 根据 object 构建url  buildUrl('category', {catid:264}) => 'category-catid-264.html'
export const buildUrl = (perfix, params) => {
    let url = perfix;
    for (var key in params) {
        if (!isEmpty(params[key])) {
            url += '-' + key + '-' + params[key];
        }
    }
    return url + '.html';
};
// 返回 关注微信公众号 链接
export const fz8cUrl = () => {
    return 'https://www.fz8c.com';
};
export const isExternalUrl = (url) => {
    return url != null && url.startsWith('http');
};
