<template>
    <div>
      <el-carousel :height="height">
        <el-carousel-item v-for="f in list" :key="f.id">
          <a
            v-if="isExternalUrl(f.www_url)"
            :href="f.www_url || '/'"
            target="_blank"
            :underline="false"
            :title="f.alt_text"
          >
             <w-img :opath="f.image" :ostyle="oStyle" :alt="f.alt_text"/>
          </a>
          <router-link
            v-else
            :to="f.www_url || '/'"
            :underline="false"
            :title="f.alt_text"
          >
             <w-img :opath="f.image" :ostyle="oStyle" :alt="f.alt_text" class="focus-img"/>
          </router-link>
        </el-carousel-item>
      </el-carousel>
    </div>
</template>

<script>
import WImg from '@/components/WImg.vue'
import { isExternalUrl } from '@/utils/link'

export default {
  name: 'focus',
  data () {
    return { isExternalUrl }
  },
  props: {
    list: { type: Array, required: true },
    oStyle: { type: String, default: 'focus300x180' },
    height: { type: String, default: '180px' }
  },
  components: {
    WImg
  }
}

</script>

<style lang="less" scoped>
.focus-img {
  width: auto;
  height: 100%;
}
</style>
