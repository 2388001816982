import { render, staticRenderFns } from "./Focus.vue?vue&type=template&id=5efad081&scoped=true"
import script from "./Focus.vue?vue&type=script&lang=js"
export * from "./Focus.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./Focus.vue?vue&type=style&index=0&id=5efad081&prod&lang=less&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5efad081",
  "70ebef02"
  
)

export default component.exports