<template>
  <div>
    <breadcrumb :items="breadcrumbItems"/>
    <div class="article-index">
      <div class="row1">
        <div class="row1-left">
          <focus :list="focus" oStyle="focus600x200" height="100px" class="row1-left-focus"/>
          <!-- TODO 最新信息未做滚动效果，目前只展示10条 -->
          <article-index-latest-info :latestInfo="latest_info" class="row1-left-latest-info"/>
        </div>
        <div class="row1-middle">
          <article-index-latest-articles :latestArticles="latest_articles"/>
        </div>
        <div class="row1-right">
          <article-index-hot-info :hotInfo="hot_info"/>
        </div>
      </div>

      <div class="row2">
        <article-img-text :latestArticles="latest_articles"/>
      </div>

      <div class="row3">
        <article-index-cat-list :cats="cats" class="row3-cats"/>
        <article-read-rank :latestArticles="latest_articles" class="row3-rank"/>
      </div>

      <div class="row4">
        <friend-links :links="f_links"/>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api/article'
import Focus from '@/components/Focus.vue'
import FriendLinks from '@/components/FriendLinks.vue'
import ArticleIndexLatestInfo from '@/views/main/article/components/ArticleIndexLatestInfo.vue'
import ArticleIndexLatestArticles from '@/views/main/article/components/ArticleIndexLatestArticles.vue'
import ArticleIndexHotInfo from '@/views/main/article/components/ArticleIndexHotInfo.vue'
import ArticleImgText from '@/views/main/article/components/ArticleImgText.vue'
import ArticleIndexCatList from '@/views/main/article/components/ArticleIndexCatList.vue'
import ArticleReadRank from '@/views/main/article/components/ArticleReadRank.vue'
import Breadcrumb from '@/components/Breadcrumb'
import { mapGetters } from 'vuex'

export default {
  name: 'article-index',
  components: {
    Focus,
    FriendLinks,
    ArticleIndexLatestInfo,
    ArticleIndexLatestArticles,
    ArticleIndexHotInfo,
    ArticleImgText,
    ArticleIndexCatList,
    ArticleReadRank,
    Breadcrumb
  },
  computed: {
    ...mapGetters({
      hot_info: 'article/index/hot_info',
      latest_info: 'article/index/latest_info',
      focus: 'article/index/focus',
      latest_articles: 'article/index/latest_articles',
      cats: 'article/index/cats',
      f_links: 'article/index/f_links'
    }),
    breadcrumbItems () {
      return [
        { name: '网站新闻', url: 'news.html' }
      ]
    }
  },
  serverPrefetch () {
    return this.fetchData()
  },
  mounted: function () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      return this.$store.dispatch('article/index/getData', this.userCity.dir || '')
    }
  },
  metaInfo () {
    return {
      title: '服装行业最新新闻-网站新闻-' + this.s.PROD_NAME,
      meta: [
        { name: 'keywords', content: '服装行业最新新闻,网站新闻，服装新闻，防骗指南，服装管理，服装心声，服装技术，' + this.s.PROD_NAME },
        { name: 'description', content: this.s.PROD_NAME + '服装新闻频道关注中国服装行业最新的新闻，内容包括服装新闻，防骗指南，服装管理，服装心声，服装技术等服装行业最新资讯' },
        { name: 'mobile-agent', content: 'format=html5; url=' + this.s.mUrl() + this.$route.fullPath }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.article-index {
  margin: 10px 0px;
}

.row1 {
  display: flex;
  justify-content: space-between;

  .row1-left-focus {
    text-align: center;
  }
}

.row3 {
  display: flex;
  justify-content: space-between;

  .row3-cats {
    flex: 0 0 710px;
  }

  .row3-rank {
    flex: 0 0 280px;
  }
}

.row4 {
  display: block;
  margin: 20px 0px 20px 0px;
}

@media (min-width: 1000px) {
  .row1, .row2, .row3 {
    flex-direction: row;
  }

  .row1-left {
    width: 300px;
    .row1-left-latest-info {
      margin-top: 10px;
      height: 610px;
    }
  }
  .row1-middle {
    width: 400px;
    .w-card {
      height: 720px;
    }
  }
  .row1-right {
    width: 280px;
    .w-card {
      height: 720px;
    }
  }
}

@media (max-width: 999px) {
  .row1, .row2, .row3 {
    flex-direction: column;
  }

  .row1 {
    .row1-left, .row3-rank, .row3-cats {
      flex: 0 1 auto;
    }

    .row1-right, .row1-left-latest-info {
      display: none;
    }
  }
}
</style>
