import { getAbout, DFT_ABOUT_ID } from '@/api/about';
const state = () => ({
    data: { title: '', content: '' }
});
// getters
const getters = {
    data: (state) => state.data
};
// actions
const actions = {
    async getData(context, id) {
        const { data } = await getAbout(id || DFT_ABOUT_ID);
        context.commit('saveData', data);
    }
};
// mutations
const mutations = {
    'saveData'(state, data) {
        state.data = data;
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
