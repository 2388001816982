<template>
  <div class="w-page about">
    <about-navigation :path="$route.path" />
    <div>
      <div class="w-title">
        <h1>{{ faq.title }}</h1>
      </div>
      <div class="w-content" v-html="faq.content" />
    </div>
    <div>
      <div class="w-content faq-cats">
        <h1>更多帮助</h1>
        <div v-for="(cat, index) in list" :key="index" class="faq-cat">
          <h2>{{ cat.type_name }}</h2>
          <ul class="w-list-v">
            <li v-for="(faq, i) in cat.faqs" :key="i">
              <router-link :to="makeFaqUrl(faq)" :title="faq.title">{{ faq.title }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AboutNavigation from '@/views/main/about/components/AboutNavigation.vue'
import { makeFaqUrl } from '@/utils/link'
import * as api from '@/api/about'
import { mapGetters } from 'vuex'

export default {
  name: 'faq',
  components: { AboutNavigation },
  computed: {
    ...mapGetters({
      faq: 'about/faq/faq',
      list: 'about/faq/list'
    })
  },
  watch: {
    '$route' (to, from) {
      this.$store.dispatch('about/faq/getFaq', this.$route.params.id || 0)
    }
  },
  serverPrefetch () {
    return this.fetchData()
  },
  mounted: function () {
    this.fetchData()
  },
  methods: {
    makeFaqUrl,
    fetchData () {
      return Promise.all([
        this.$store.dispatch('about/faq/getFaq', this.$route.params.id || 0),
        this.$store.dispatch('about/faq/getList')
      ])
    }
  },
  metaInfo () {
    return {
      title: this.faq.title + '-网站帮助-' + this.s.PROD_NAME,
      meta: [
        { name: 'keywords', content: this.s.PROD_NAME + '，网站帮助，' + this.faq.title },
        { name: 'description', content: this.s.PROD_NAME + '网站公告频道-' + this.faq.title }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.faq-cats {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #f0f2f7;
}
.faq-cats a {
  margin-right: 10px;
  text-decoration: none;
}

.faq-cat {
  margin-top: 20px;
}
</style>
