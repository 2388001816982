import 'normalize.css';
import './plugins/element.js';
import '@/assets/libs/iconfont/iconfont.css';
import '@/assets/css/global.css';
import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import track from '@/plugins/track';
import seo from '@/plugins/seo';
import createRouter from './router';
import createStore from './store';
import root from '@/mixins/root';
import base from '@/mixins/base';
import VueMeta from 'vue-meta';
import '@/filters/';
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(track).use(seo);
Vue.mixin(base);
Vue.use(VueMeta, {
    // optional pluginOptions
    ssrAppId: '1', // 修复vue-meta标记重复的问题
    refreshOnceOnNavigation: true
});
export const createApp = function (context) {
    const store = createStore();
    const router = createRouter();
    // 同步获取城市， 不能同步执行，如果同步，ssr报错。
    store.dispatch('global/syncCityFromUrl', context.url);
    const app = new Vue({
        mixins: [root],
        router,
        store,
        render: h => h(App)
    });
    return { app, router, store };
};
