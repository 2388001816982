import request from '@/utils/request';
// 会返回按照省份的城市和热门城市
export const getCities = () => request({
    url: `/region/cities`,
    method: 'get'
});
export const getHotCities = () => request({
    url: `/region/hot_cities_by_id`,
    method: 'get'
});
export const getCity = (params) => request({
    url: `/region/city`,
    method: 'get',
    params
});
export const getNearbyCities = (params) => request({
    url: `/region/nearby_cities`,
    method: 'get',
    params
});
