<template>
  <div>
    <breadcrumb :items="breadcrumbItems"/>
    <div>
       <div class="row1">
         <div class="row1-main">
           <article-cat-list :catData="cat_data" :cat="cat"/>
         </div>
         <div class="row1-right">
           <search-article />
           <article-cat-latest-info :latestInfo="latest_info"/>
           <article-img-text :latestArticles="latest_articles.list"/>
           <article-read-rank :latestArticles="latest_articles.list"/>
         </div>
       </div>
       <!-- TODO 搜索框 -->
       <div class="row2">
       </div>
    </div>
  </div>
</template>

<script>
import SearchArticle from '@/views/main/article/components/SearchArticle.vue'
import ArticleCatLatestInfo from '@/views/main/article/components/ArticleCatLatestInfo.vue'
import ArticleImgText from '@/views/main/article/components/ArticleImgText.vue'
import ArticleReadRank from '@/views/main/article/components/ArticleReadRank.vue'
import ArticleCatList from '@/views/main/article/components/ArticleCatList.vue'
import Breadcrumb from '@/components/Breadcrumb'
import { parseParams } from '@/utils/link'
import * as api from '@/api/article'
import { mapGetters } from 'vuex'

export default {
  components: {
    SearchArticle,
    ArticleCatLatestInfo,
    ArticleImgText,
    ArticleReadRank,
    ArticleCatList,
    Breadcrumb
  },
  data: function () {
    return { params: { page: 1 } }
  },
  computed: {
    ...mapGetters({
      cat_data: 'article/cat_list/cat_data',
      cat: 'article/cat_list/cat',
      latest_info: 'article/cat_list/latest_info',
      latest_articles: 'article/cat_list/latest_articles'
    }),
    breadcrumbItems () {
      return [
        { name: '网站新闻', url: 'news.html' },
        { name: this.cat.name, url: this.$route.path }
      ]
    }
  },
  serverPrefetch () {
    return this.fetchData()
  },
  mounted: function () {
    this.fetchData()
  },
  watch: {
    '$route' (to, from) {
      this.fetchData()
    }
  },
  methods: {
    load: function () {
    },
    fetchData () {
      const city = this.userCity
      this.params = parseParams(this.$route.params.PARA_TEXT)
      return this.$store.dispatch('article/cat_list/getData', {
        lon: city.lon,
        lat: city.lat,
        catid: this.params.catid,
        page: this.params.page ? params.page : '1',
        perPage: 20
      })
    }
  },
  metaInfo () {
    const c = this.cat
    const page = this.params.page ? '-第' + this.params.page + '页' : ''
    return {
      title: c.name + '-网站新闻-' + this.s.PROD_NAME + page,
      meta: [
        { name: 'keywords', content: c.keywords },
        { name: 'description', content: c.description },
        { name: 'mobile-agent', content: 'format=html5; url=' + this.s.mUrl() + this.$route.fullPath }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.row1 {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

}

@media (min-width: 1000px) {
  .row1, .row2 {
    flex-direction: row;
  }

  .row1-main {
    width: 650px;
  }

  .row1-right {
    width: 340px;
    margin-left: 10px;
  }
}

@media (max-width: 999px) {
  .row1, .row2 {
    flex-direction: column;
  }

  .row1-main, .row1-right{
      width: 100%;
    }

}
</style>
