import { getIndexData } from '@/api/article';
const state = () => ({
    hot_info: [],
    latest_info: [],
    focus: [],
    latest_articles: [{ id: 1, title: '', introduction: '' }],
    cats: [],
    f_links: []
});
// getters
const getters = {
    hot_info: (state) => state.hot_info,
    latest_info: (state) => state.latest_info,
    focus: (state) => state.focus,
    latest_articles: (state) => state.latest_articles,
    cats: (state) => state.cats,
    f_links: (state) => state.f_links
};
// actions
const actions = {
    async getData(context, city_dir) {
        const { data } = await getIndexData({ city_dir });
        context.commit('saveHotInfo', data.hot_info);
        context.commit('saveLatestInfo', data.latest_info);
        context.commit('saveFocus', data.focus);
        context.commit('saveLatestArticle', data.latest_articles.list);
        context.commit('saveCats', data.cats);
        context.commit('saveFLinks', data.f_links);
    }
};
// mutations
const mutations = {
    'saveHotInfo'(state, hot_info) {
        state.hot_info = hot_info;
    },
    'saveLatestInfo'(state, latest_info) {
        state.latest_info = latest_info;
    },
    'saveFocus'(state, focus) {
        state.focus = focus;
    },
    'saveLatestArticle'(state, latest_articles) {
        state.latest_articles = latest_articles;
    },
    'saveCats'(state, cats) {
        state.cats = cats;
    },
    'saveFLinks'(state, f_links) {
        state.f_links = f_links;
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
