import { render, staticRenderFns } from "./ShopAboutPage.vue?vue&type=template&id=ae04f9dc&scoped=true"
import script from "./ShopAboutPage.vue?vue&type=script&lang=js"
export * from "./ShopAboutPage.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "ae04f9dc",
  "6b44396f"
  
)

export default component.exports