import { render, staticRenderFns } from "./MsgListPage.vue?vue&type=template&id=1e998338&scoped=true"
import script from "./MsgListPage.vue?vue&type=script&lang=js"
export * from "./MsgListPage.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "1e998338",
  "f4ee8bc6"
  
)

export default component.exports