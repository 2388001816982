<template>
  <header>
    <el-backtop></el-backtop>
    <el-row class="header-row1">
      <div class="w-body">
        <div class="w-main">
          <el-col :span="16">
            <div class="city-links">
              <span class="color-highlight"><i class="el-icon-location"/>{{userCity.name}}</span>
              <router-link :to="'/cities.html'" class="btn-switch">切换城市</router-link>
            </div>
            <div class="quick-links">
              <a class="w-qrcode" :href="fz8cUrl()">
                <i class="el-icon-position"></i>发布信息
                <img style="display: none" src="@/assets/img/fz8c_xcx.png" alt="服装八场公众号"/>
              </a>
              <a class="w-qrcode" :href="fz8cUrl()">
                <i class="el-icon-position"></i>下载App
                <img style="display: none" src="@/assets/img/fz8c_app.png" alt="服装八场手机App"/>
              </a>
              <a class="w-qrcode" :href="fz8cUrl()">
                <i class="el-icon-position"></i>微信小程序
                <img style="display: none" src="@/assets/img/fz8c_xcx.png" alt="服装八场微信小程序"/>
              </a>
              <a class="w-qrcode" :href="fz8cUrl()">
                <i class="el-icon-position"></i>微信公众号
                <img style="display: none" src="@/assets/img/fz8c68.png" alt="服装八场公众号"/>
              </a>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="user-links">
              您好，请<a class="color-highlight w-qrcode" :href="fz8cUrl()">登录
                <img style="display: none" src="@/assets/img/fz8c_app.png" alt="服装八场手机App"/>
              </a>或者
              <a class="color-highlight w-qrcode" :href="fz8cUrl()">注册
                <img style="display: none" src="@/assets/img/fz8c_app.png" alt="服装八场手机App"/>
              </a>
            </div>
          </el-col>
        </div>
        <div class="w-left"></div>
        <div class="w-right"></div>
      </div>
    </el-row>
    <el-row class="header-row2">
      <div class="w-body header-row2-1">
        <div class="w-main header-main">
          <div class="header-main-p1 w-align">
            <div class="w-align-item-center">
              <router-link :to="'/'" title="服装八场" class="logo-link">
                <img src="@/assets/logo_400x400.png" class="header-logo" title="服装八场" alt="服装八场"/>
                <img src="@/assets/fz8c_800x400.png" class="header-slogan" title="服装八场，诚信赢天下" alt="服装八场，诚信赢天下"/>
              </router-link>
            </div>
          </div>
          <div class="header-main-p2 w-align">
            <search-box class="w-align-item-center"/>
          </div>
          <div class="header-main-p3 w-align">
            <div class="w-align-item-center">
              <a :href="fz8cUrl()" class="btn-publish w-qrcode">免费发布
                <img style="display: none" src="@/assets/img/fz8c_app.png" alt="服装八场手机App"/>
              </a>
            </div>
          </div>
        </div>
        <div class="w-left"></div>
        <div class="w-right"></div>
      </div>
      <div class="w-body header-row2-2">
        <div class="w-main main-menus">
          <nav-menus/>
        </div>
        <div class="w-left"></div>
        <div class="w-right"></div>
      </div>
    </el-row>
  </header>
</template>

<script>
import SearchBox from '@/views/layout/components/SearchBox'
import NavMenus from '@/views/layout/components/NavMenus'
import { fz8cUrl } from '@/utils/link'

export default {
  components: {
    SearchBox,
    NavMenus
  },
  methods: {
    fz8cUrl
  }
}
</script>

<style lang="less" scoped>
.header-row1 {
  height: 40px;
  line-height: 40px;
}
.header-row2 {
  z-index: 0;
}
.header-row2-1 {
  height: 90px;
  background-color: #32b6fa;
}
.btn-switch {
  margin-left: 5px;
}
.color-highlight {
  color: #ff8903;
}

.header-row2-2 {
  background-color: #45a3fa;
}

.header-main {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.header-main-p1 {
  flex: 250;
  margin: 0;
}

.header-main-p2 {
  flex: 500;
}
.header-main-p3 {
  flex: 250;
}
.logo-link {
  display: block;
  width: 250px;
  margin-top: 10px;
}
.header-logo {
  width: 80px;
}
.header-slogan {
  width: 170px;
}
.btn-publish {
  background-color: #fff;
  color: #039bda;
  border-radius: 1em;
  width:100px;
  line-height: 35px;
  text-align: center;
  display: block;
}
.city-links {
  display: inline-block;
}
.quick-links {
  display: inline-block;
  margin-left: 15px;
}
.quick-links a {
  margin-right: 10px;
}
.user-links {
  text-align: right;
  color: #606266;
  font-size: 14px;
}
.main-menus {
  height: 60px;
}
</style>
