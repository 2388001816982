export const getString = (val, dftVal) => {
    if (val === undefined || val === null || val === '') {
        return dftVal;
    }
    else {
        return val;
    }
};
export const isEmpty = (val) => {
    if (val === null || val === undefined) {
        return true;
    }
    // 判断数字是否是NaN
    if (typeof val === 'number') {
        if (isNaN(val)) {
            return true;
        }
        else {
            return false;
        }
    }
    // 判断参数是否是布尔、函数、日期、正则，是则返回false
    if (typeof val === 'boolean' || typeof val === 'function' || val instanceof Date || val instanceof RegExp) {
        return false;
    }
    // 判断参数是否是字符串，去空，如果长度为0则返回true
    if (typeof val === 'string') {
        if (val.trim().length === 0) {
            return true;
        }
        else {
            return false;
        }
    }
    if (typeof val === 'object') {
        // 判断参数是否是数组，数组为空则返回true
        if (val instanceof Array) {
            if (val.length === 0) {
                return true;
            }
            else {
                return false;
            }
        }
        // 判断参数是否是对象，判断是否是空对象，是则返回true
        if (val instanceof Object) {
            // 判断对象属性个数
            if (Object.getOwnPropertyNames(val).length === 0) {
                return true;
            }
            else {
                return false;
            }
        }
    }
};
