<template>
  <div class="w-page">
    <about-navigation :path="$route.path"/>
    <div class="w-title">
      <h1>服装行业信息分类</h1>
    </div>
    <div class="w-content">
      <el-row
        v-for="(row, index) in list"
        :key="index"
      >
        <h2>
          <router-link :to="makeInfoCatUrl(row)">{{row.name}}</router-link>
        </h2>
        <el-col v-for="(c, i) in row.children" :key="i" :xs="6" :sm="4" :md="3">
          <router-link :to="makeInfoCatUrl(c)" :title="userCity.name+c.title">{{c.name}}</router-link>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import AboutNavigation from '@/views/main/about/components/AboutNavigation.vue'
import { makeInfoCatUrl } from '@/utils/link'
import { mapGetters } from 'vuex'

export default {
  name: 'siteMap',
  components: { AboutNavigation },
  computed: {
    ...mapGetters({
      list: 'about/site_map/cats'
    })
  },
  serverPrefetch () {
    return this.fetchData()
  },
  mounted: function () {
    this.fetchData()
  },
  methods: {
    makeInfoCatUrl,
    fetchData () {
      return this.$store.dispatch('about/site_map/getCats')
    }
  },
  metaInfo () {
    return {
      title: '网站地图-' + this.s.PROD_NAME,
      meta: [
        { name: 'keywords', content: '网站地图，服装分类信息，' + this.s.PROD_NAME },
        { name: 'description', content: this.s.PROD_NAME + '网站地图频道，提供各类服装分类信息' }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.w-content h2 {
  margin: 20px 0px;
}
</style>
