<template>
  <div class="fl-container">
    <h3>友情链接<router-link :to="'/friendlink.html'" class="btn-apply">申请链接</router-link></h3>
    <ul class="w-list-h">
      <li v-for="(link,i) in links" :key="i">
        <a :underline="false" :href="link.url" target="_blank">{{link.name}}</a>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  props: ['links']
}
</script>

<style lang="less" scoped>
.fl-container {
  color: #aaa;
  margin-bottom: 10px;
  h3 {
    margin-bottom: 10px;
  }
  .btn-apply {
    color: #666;
    margin-left: 5px;
    padding-left: 5px;
    border-left: #666 1px solid;
  }
  li {
    margin: 0px 10px 6px 0;
  }
  a {
    color: #aaa;
  }
}
</style>
