
<template>
  <nav class="about-nav">
      <router-link v-for="(link, i) in links" :key="i" :to="link.url" :class="link.class">{{link.name}}</router-link>
  </nav>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'

const links = [
  { name: '关于我们', class: '', url: 'aboutus-id-1.html' },
  { name: '广告服务', class: '', url: 'aboutus-id-2.html' },
  { name: '联系我们', class: '', url: 'aboutus-id-3.html' },
  { name: '网站公告', class: '', url: 'announce.html' },
  { name: '帮助中心', class: '', url: 'faq.html' },
  { name: '友情链接', class: '', url: 'friendlink.html' },
  { name: '网站地图', class: '', url: 'sitemap.html' }
]

export default {
  name: 'about-navigation',
  props: ['path'],

  created () {
    this.load()
  },
  watch: {
    '$route.path': {
      handler: function () {
        this.load()
      },
      deep: true,
      immediate: true
    }
  },
  data () {
    return {
      currentUrl: '',
      links
    }
  },

  methods: {
    load () {
      let str = this.path.split('/')[1]
      if (str.indexOf('about') !== -1) {
        this.currentUrl = str
      } else if (str.indexOf('-') > 0) {
        this.currentUrl = str.split('-')[0] + '.html'
      } else {
        this.currentUrl = str
      }

      links.filter((link) => {
        if (this.currentUrl === 'aboutus.html') {
          link.class = link.url === 'aboutus-id-1.html' ? 'about-link active' : 'about-link'
        } else {
          link.class = link.url === this.currentUrl ? 'about-link active' : 'about-link'
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.about-nav{
  border-bottom: 1px solid #dcdfe6;
  text-align: center;
}

.about-link {
  font-size: 14px;
  margin: 20px;
  display: inline-block;
}

.active {
  color: #0084ff;
  font-weight: 500;
}

</style>
