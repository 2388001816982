import { render, staticRenderFns } from "./SearchCatOfHotCities.vue?vue&type=template&id=63046a8e&scoped=true"
import script from "./SearchCatOfHotCities.vue?vue&type=script&lang=js"
export * from "./SearchCatOfHotCities.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./SearchCatOfHotCities.vue?vue&type=style&index=0&id=63046a8e&prod&lang=less&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "63046a8e",
  "545f73d8"
  
)

export default component.exports