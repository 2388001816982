import { getFLinks } from '@/api/about';
const state = () => ({
    links: []
});
// getters
const getters = {
    links: (state) => state.links
};
// actions
const actions = {
    async getLinks(context, params) {
        const { data } = await getFLinks(params);
        context.commit('savelinks', data);
    }
};
// mutations
const mutations = {
    'savelinks'(state, links) {
        state.links = links;
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
