<template>
  <div class="w-card cat-list">
    <h2>感兴趣的分类</h2>
    <ul>
      <li v-for="cat in cats" :key="cat.id">
        <router-link :to="buildUrl('category', {catid: cat.id})" :title="cat.title">{{cat.name}}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { buildUrl } from '@/utils/link'

export default {
  props: ['cats'],
  methods: {
    buildUrl
  }
}
</script>

<style lang="less" scoped>
.cat-list {
  h2 {
    font-size: 14px;
    font-weight: 700;
    padding: 15px 0 0 15px;
    color: #333;
  }
  font-size: 12px;
  ul {
    padding-inline-start: 20px;
  }
  li {
    float: left;
    overflow: hidden;
    white-space: nowrap;
    width: 100px;
    padding: 5px 0;
  }
}
</style>
