import { render, staticRenderFns } from "./ArticleCatList.vue?vue&type=template&id=25b3acee&scoped=true"
import script from "./ArticleCatList.vue?vue&type=script&lang=js"
export * from "./ArticleCatList.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./ArticleCatList.vue?vue&type=style&index=0&id=25b3acee&prod&lang=less&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "25b3acee",
  "f8f159e8"
  
)

export default component.exports