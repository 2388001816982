import { render, staticRenderFns } from "./ShopCatPage.vue?vue&type=template&id=64c53000&scoped=true"
import script from "./ShopCatPage.vue?vue&type=script&lang=js"
export * from "./ShopCatPage.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "64c53000",
  "2f51c778"
  
)

export default component.exports