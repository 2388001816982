import { render, staticRenderFns } from "./RecordListPage.vue?vue&type=template&id=12732658&scoped=true"
import script from "./RecordListPage.vue?vue&type=script&lang=js"
export * from "./RecordListPage.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "12732658",
  "c2f99742"
  
)

export default component.exports