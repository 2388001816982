import request from '@/utils/request';
export const login = (paras) => request({
    url: 'auth/login',
    method: 'post',
    data: paras
});
export const getPublicInfo = (userid) => request({
    url: 'user/public_info',
    method: 'post',
    data: { userid }
});
