<template>
    <div class="w-card">
      <h2 class="title">相关推荐</h2>
      <ul class="w-list-v">
        <li v-for="(a,index) in articles" :key="index">
          <simple-article-row :article="a" :displayTime="true"/>
        </li>
      </ul>
    </div>
</template>

<script>
import SimpleArticleRow from '@/components/SimpleArticleRow.vue'

export default {
  props: ['articles'],
  components: {
    SimpleArticleRow
  }
}
</script>

<style lang="less" scoped>
.title {
    padding: 20px 0 10px 15px;
}
</style>
