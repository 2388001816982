import request from '@/utils/request';
export const getIndexData = (data) => request({
    url: `page/w_news_index`,
    method: 'post',
    data
});
export const getCatListData = (data) => request({
    url: 'page/w_news_cat',
    method: 'post',
    data
});
export const getDetailPageData = (data) => request({
    url: 'page/w_news_detail',
    method: 'post',
    data
});
