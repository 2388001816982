export const inDays = (d1, d2) => {
    const t2 = d2.getTime();
    const t1 = d1.getTime();
    return (t2 - t1) / (24 * 3600 * 1000);
};
export const inWeeks = (d1, d2) => {
    const t2 = d2.getTime();
    const t1 = d1.getTime();
    return (t2 - t1) / (24 * 3600 * 1000 * 7);
};
export const inMonths = (d1, d2) => {
    const d1Y = d1.getFullYear();
    const d2Y = d2.getFullYear();
    const d1M = d1.getMonth();
    const d2M = d2.getMonth();
    return d2M + 12 * d2Y - (d1M + 12 * d1Y);
};
export const inYears = (d1, d2) => {
    return d2.getFullYear() - d1.getFullYear();
};
// 是否闰年
export const isLeapYear = (a) => {
    return a % 400 === 0 || (a % 4 === 0 && a % 100 !== 0);
};
// 月内有几天
export const daysInMonth = (a, b) => {
    return [null, 31, null, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][b] || (isLeapYear(a) ? 29 : 28);
};
export const format = (d1, d2) => {
    if (!(d1 instanceof Date) || !(d2 instanceof Date)) {
        return '';
    }
    let days = inDays(d1, d2);
    const years = days / 365;
    const months = inMonths(d1, d2) - years * 12;
    const daysOfd1Month = daysInMonth(d1.getFullYear(), d1.getMonth());
    days = (days % 365) % daysOfd1Month;
    let text = years > 0 ? years + '年' : '';
    text += months > 0 ? months + '个月' : '';
    text += days > 0 ? days + '天' : '';
    return text;
};
export const formatTime = (d, fmt) => {
    const o = new Map([
        ['M+', d.getMonth() + 1], // 月份
        ['d+', d.getDate()], // 日
        ['h+', d.getHours()], // 小时
        ['m+', d.getMinutes()], // 分
        ['s+', d.getSeconds()], // 秒
        ['q+', Math.floor((d.getMonth() + 3) / 3)], // 季度
        ['S+', d.getMilliseconds()] // 毫秒
    ]);
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (d.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    o.forEach((v, k) => {
        if (new RegExp('(' + k + ')').test(fmt)) {
            let value = v.toString();
            // 按照格式要求补足0
            value = value.padStart(RegExp.$1.length, '0');
            fmt = fmt.replace(RegExp.$1, value);
        }
    });
    return fmt;
};
export const parse = (s, fmt) => {
    if (!s || !fmt) {
        return null;
    }
    if (s instanceof Date) {
        return s;
    }
    if (s.length !== fmt.length) {
        return null;
    }
    // 按照format中的位置去s中取出相应的值，并做解析。
    const o = new Map([
        ['y', { t: 'y' }], // 月份
        ['M', { t: 'M' }], // 日
        ['d', { t: 'd' }], // 小时
        ['h', { t: 'h' }], // 分
        ['m', { t: 'm' }], // 秒
        ['s', { t: 's' }], // 季度
        ['q', { t: 'q' }], // 毫秒
        ['S', { t: 'S' }] //
    ]);
    o.forEach((v, k) => {
        const f = fmt.indexOf(o.get(k).t);
        const l = fmt.lastIndexOf(o.get(k).t) + 1;
        v.v = f < 0 || l < 0 ? 0 : parseInt(s.substring(f, l), 10);
        o.set(k, v);
    });
    return new Date(o.get('y').v, o.get('M').v - 1, o.get('d').v, o.get('h').v, o.get('m').v, o.get('s').v, o.get('S').v);
};
export const isWorkingDay = (date) => {
    const hdays = [
        { n: '元旦', s: 81230, e: 90101 },
        { n: '春节', s: 90204, e: 90210 },
        { n: '清明节', s: 90405, e: 90407 },
        { n: '劳动节', s: 90501, e: 90501 },
        { n: '端午节', s: 90607, e: 90609 },
        { n: '中秋节', s: 90913, e: 90915 },
        { n: '国庆节', s: 91001, e: 91007 }
    ]; // 假日数据
    const wDays = [81229, 90202, 90203, 90929, 91012]; // working days 调休上班, 填入必须工作的日期
    const d = parseInt(formatTime(date, 'yMMdd'), 10);
    hdays.forEach((h) => {
        if (d >= h.s && d <= h.e) {
            return false;
        }
    });
    if (wDays.indexOf(d) >= 0) {
        // 调休上班
        return true;
    }
    else {
        return date.getDay() >= 1 && date.getDay() <= 5;
    }
};
export const getDate = (v = null) => {
    if (!v) {
        return new Date();
    }
    if (v instanceof Date) {
        return v;
    }
    if (typeof v === 'string') {
        // 兼容文本格式的timestamp值。
        if (/^[0-9]+$/.test(v)) {
            return new Date(parseInt(v));
        }
        // ios下解析 '2015-06-29 23:59:59' 报错
        // 参考: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
        const b = v.split(new RegExp('[^0-9]')).map(function (v) {
            return parseInt(v);
        });
        // v的值可能不存在时分秒，不存在则赋值为0
        return new Date(b[0], b[1] - 1, b[2], b[3] || 0, b[4] || 0, b[5] || 0);
    }
    return new Date(v);
};
