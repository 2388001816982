import request from '@/utils/request';
import settings from '@/core/settings';
export const DFT_FAQ_ID = 2;
export const DFT_ABOUT_ID = 1;
export const ERR_DATA = {
    title: '发生未知的错误！',
    content: `发生未知的错误！请稍后再刷新一下，如果一直这样请联系微信客服${settings.WX_KF_NAME},
  微信号： ${settings.WX_KF_ID}`
};
export const getAbout = (id) => request({
    url: `about/${id}`,
    method: 'get'
});
export const getAnnounce = (id) => request({
    url: `announce/${id}`,
    method: 'get'
});
export const getAnnounceList = (params) => request({
    url: 'announce/list',
    method: 'get',
    params
});
export const getFaq = (id) => request({
    url: `faq/${id}`,
    method: 'get'
});
export const getFaqList = () => request({
    url: 'faq',
    method: 'get'
});
export const getFLinks = (params) => request({
    url: 'flink',
    method: 'get',
    params
});
export const createFLink = (data) => request({
    url: 'flink',
    method: 'post',
    data
});
